import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteblogAction,
  getbloglistAction,
} from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import Pagination from "../Components/Pagination";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { Button } from "react-bootstrap";
import DeleteComponent from "../Components/DeleteComponent";

export default function Blog() {
  const [pageno, setPageno] = useState(1);
  const [idvalue, setIdvalue] = useState("");
  const [show, setShow] = useState(false);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getbloglistAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const getbloglists = useSelector((state) => state.Administrator.getbloglists);

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const deletefaq = () => {
    dispatch(
      deleteblogAction({
        id: idvalue,
      })
    ).then(function() {
      navigate("/blog");
      dispatch(
        getbloglistAction({
          perpage: perpage,
          pageno: pageno,
          tab: tab,
          serachkeyval: serachkeyval,
        })
      );
      setShow(false);
    });
  };
  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Blogs</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Created Date</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Created By</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </Col>
              <Col
                xxl={5}
                xl={3}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/create-blog"> CREATE BLOG</Link>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " - " +
                      (getbloglists?.Totalblog > 10
                        ? rangeEnd
                        : getbloglists?.Totalblog) +
                      " of " +
                      getbloglists?.Totalblog?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${
                      getbloglists?.publishblog > 10
                        ? rangeEnd
                        : getbloglists?.publishblog
                    } of  ${getbloglists?.publishblog?.toLocaleString(
                      "en-US"
                    )} `;
                  } else {
                    return `${rangeStart} - ${
                      getbloglists?.draftblog > 10
                        ? rangeEnd
                        : getbloglists?.draftblog
                    } of  ${getbloglists?.draftblog?.toLocaleString("en-US")} `;
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>ID #</th>
                <th>CREATED</th>
                {/* <th>CREATED By</th> */}
                <th>BLOG TITLE</th>
                <th>NEWS</th>
                <th>STATUS</th>
                <th>LAST EDITED </th>
                <th>LAST EDITED BY</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {getbloglists?.alllist?.map(function(bolgobject, i) {
                return (
                  <tr>
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td
                      onClick={() => {
                        navigate("/edit-blog/" + Encryptedid(bolgobject?._id));
                      }}
                    >
                      {moment(bolgobject?.createdAt).format("MM/DD/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(bolgobject?.createdAt).format("HH:mm")}
                      </span>
                    </td>
                    {/* <td
                      onClick={() => {
                        navigate("/edit-blog/" + Encryptedid(bolgobject?._id));
                      }}
                    >
                      <Link>{bolgobject?.Author}</Link>
                    </td> */}
                    <td
                      onClick={() => {
                        navigate("/edit-blog/" + Encryptedid(bolgobject?._id));
                      }}
                    >
                      {bolgobject?.blogtitle}
                    </td>
                    <td>{bolgobject?.publishnewtab == 0 ? "No" : "Yes"}</td>
                    <td>
                      {bolgobject?.publishstatus == 1 ? "Published" : "Draft"}
                    </td>

                    <td>
                      {moment(bolgobject?.updatedAt).format("MM/DD/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(bolgobject?.updatedAt).format("HH:mm")}
                      </span>
                    </td>
                    <td>
                      {bolgobject?.user_detail?.name == undefined
                        ? bolgobject?.user_detail?.firstName +
                          bolgobject?.user_detail?.lastName
                        : bolgobject?.user_detail?.name}
                    </td>

                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setIdvalue(bolgobject?._id);
                          setShow(true);
                        }}
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={getbloglists?.totalpagination}
        paginationevent={paginationevent}
      />
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"Faq"}
        Id={idvalue}
        deleteuser={deletefaq}
      />
    </Layout>
  );
}
