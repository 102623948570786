import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  arrangeFAQListOrderAction,
  deletefaqAction,
  getfaqlistAction,
} from "../Redux/Action/AdminstratorAction";
import { Encryptedid } from "../Util/BcruptEncyptid";
import Pagination from "../Components/Pagination";
import DeleteComponent from "../Components/DeleteComponent";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function Faqs() {
  const [idvalue, setIdvalue] = useState("");
  const [show, setShow] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getfaqlistAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const faqlist = useSelector((state) => state?.Administrator?.faqlist);
  const [state, setState] = useState({});

  useEffect(() => {
    if (faqlist) {
      setState({ ...state, ...faqlist })
    }
  }, [faqlist])

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const deletefaq = () => {
    dispatch(
      deletefaqAction({
        id: idvalue,
      })
    ).then(function () {
      navigate("/faqs");
      dispatch(
        getfaqlistAction({
          perpage: perpage,
          pageno: pageno,
          tab: tab,
          serachkeyval: serachkeyval,
        })
      );
      setShow(false);
    });
  };
  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };

  const DraggableItem = ({ item, index, moveItem }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = draggedItem.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        moveItem(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
    });

    const [, drag] = useDrag({
      type: "ITEM",
      item: { index },
      end: (draggedItem) => {
        // Finalize the move when dragging ends
        moveItem(draggedItem.index, index, true);
      },
    });

    drag(drop(ref));

    return (
      <>
        <tr ref={ref} key={index} >
          <td >
            <div className="d-flex align-items-center" style={{ cursor: "move" }}>
              <svg
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1.5" cy="1.5" r="1.5" fill="#171717" />
                <circle cx="7.5" cy="1.5" r="1.5" fill="#171717" />
                <circle cx="1.5" cy="7.5" r="1.5" fill="#171717" />
                <circle cx="7.5" cy="7.5" r="1.5" fill="#171717" />
                <circle cx="1.5" cy="13.5" r="1.5" fill="#171717" />
                <circle cx="7.5" cy="13.5" r="1.5" fill="#171717" />
              </svg>
              <span className="ms-3">
                {index + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}
              </span>
            </div>
          </td>
          <td
            onClick={() => {
              navigate("/edit-faq/" + Encryptedid(item?._id));
            }}
          >
            {item?.question}
          </td>
          <td>{item?.answer}</td>
          <td>
            <Button
              className="account-btn common-colr-btn"
              onClick={() => {
                setIdvalue(item?._id);
                setShow(true);
              }}
            >
              Delete
            </Button>{" "}
          </td>
        </tr>
      </>
    );
  };


  const moveItem = (fromIndex, toIndex, finalize = false) => {
    const updatedItems = [...state?.alllist];
    const [movedItem] = updatedItems.splice(toIndex, 1);
    updatedItems.splice(fromIndex, 0, movedItem);

    setState({ ...state, alllist: updatedItems })

    if (finalize) {
      const allFAQIds = updatedItems?.map((res, i) => {
        let payload = {
          _id: res?._id,
          new_s_no: i
        }
        return payload
      });

      console.log("updatedItems FAQ", allFAQIds)

      dispatch(
        arrangeFAQListOrderAction({
          faq_data: allFAQIds,
          perpage: Number(perpage),
          pageno: pageno,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(
            getfaqlistAction({
              perpage: Number(perpage),
              pageno: pageno,
              tab: tab,
              serachkeyval: serachkeyval,
            })
          );
        }
      });
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All FAQs</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/create-faq">ADD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (state?.Totalfaq > 10 ? rangeEnd : state?.Totalfaq) +
                    " of " +
                    state?.Totalfaq?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Question</th>
                <th>Answer</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <DndProvider backend={HTML5Backend}>
                {state?.alllist?.length > 0 ? (
                  state?.alllist?.map((item, index) => {
                    return (
                      <DraggableItem
                        key={item?._id}
                        item={item}
                        index={index}
                        moveItem={moveItem}
                      />
                    );
                  })
                ) : (
                  <div className="data-not-found">
                    <p>No Data Found</p>
                  </div>
                )}
              </DndProvider>
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={state?.totalpagination}
        paginationevent={paginationevent}
      />
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"Faq"}
        Id={idvalue}
        deleteuser={deletefaq}
      />
    </Layout>
  );
}
