import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  arrangeEmployeeListOrderAction,
  deleteemployeeAction,
  gelistsexportAction,
  getemployeelistAction,
} from "../Redux/Action/AdminstratorAction";
import { Encryptedid } from "../Util/BcruptEncyptid";
import Pagination from "../Components/Pagination";
import DeleteComponent from "../Components/DeleteComponent";
import DownloadComponent from "../Components/DownloadComponent";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function ManageEmployees() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [idvalue, setIdvalue] = useState("");
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getemployeelistAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const employeelist = useSelector((state) => state.Administrator.employeelist);

  const [state, setState] = useState({});

  useEffect(() => {
    if (employeelist) {
      setState({ ...state, ...employeelist })
    }

  }, [employeelist])

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };
  const deleteemployee = () => {
    dispatch(
      deleteemployeeAction({
        id: idvalue,
      })
    ).then(function () {
      navigate("/manage-employees");
      dispatch(
        getemployeelistAction({
          perpage: perpage,
          pageno: pageno,
          tab: tab,
          serachkeyval: serachkeyval,
        })
      );
      setShow(false);
    });
  };


  const DraggableItem = ({ item, index, moveItem }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem, monitor) => {
        if (!ref.current) {
          return;
        }

        const dragIndex = draggedItem.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }
        moveItem(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
    });

    const [, drag] = useDrag({
      type: "ITEM",
      item: { index },
      end: (draggedItem) => {
        // Finalize the move when dragging ends
        moveItem(draggedItem.index, index, true);
      },
    });

    drag(drop(ref));

    return (
      <>
        <tr ref={ref} key={index} >
          <td style={{ cursor: "move" }}>
            <svg
              width="9"
              height="15"
              viewBox="0 0 9 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#171717" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#171717" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#171717" />
            </svg>
            <span className="ms-4">
              {index + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>{item?.phoneNumber} {" "}{item?.extension}</td>
          <td className="user-img">
            <img src={item?.profileImage} />
          </td>

          <td>{item?.designation}</td>

          <td className="btn-set">
            <div
              className="account-btn common-colr-btn"
              onClick={() => {
                navigate(
                  "/view-employee/" + Encryptedid(item?._id)
                );
              }}
            >
              View
            </div>{" "}
            <Button
              className="account-btn common-colr-btn"
              onClick={() => {
                setIdvalue(item?._id);
                setShow(true);
              }}
            >
              Delete
            </Button>{" "}
          </td>
        </tr>
      </>
    );
  };

  const moveItem = (fromIndex, toIndex, finalize = false) => {
    const updatedItems = [...state?.alllist];
    const [movedItem] = updatedItems.splice(toIndex, 1);
    updatedItems.splice(fromIndex, 0, movedItem);

    setState({ ...state, alllist: updatedItems })
    
    if (finalize) {
      const allEmpIds = updatedItems?.map((res, i) => {
        let payload = {
          _id: res?._id,
          new_s_no: i
        }
        return payload
      });

      console.log("allEmpIds", allEmpIds)
      dispatch(arrangeEmployeeListOrderAction({
          orderedIds: allEmpIds,
          perpage: Number(perpage),
          pageno: pageno,
        })
      ).then((res) => {
        console.log("res", res)
        if (res?.payload?.status === 200) {
          dispatch(
            getemployeelistAction({
              perpage: Number(perpage),
              pageno: pageno,
              tab: tab,
              serachkeyval: serachkeyval,
            })
          );
        }
      });
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Employess</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>

              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn me-2">
                  <Link to="/create-employee">Add Employee </Link>
                </div>

                <div className="cmn-btn">
                  <DownloadComponent action={gelistsexportAction} serachkeyval={serachkeyval} />
                </div>

              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (state?.totalpagination > 10
                      ? rangeEnd
                      : state?.totalpagination) +
                    " of " +
                    state?.totalpagination?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Profile Picture</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <DndProvider backend={HTML5Backend}>
                {state?.alllist?.length > 0 ? (
                  state?.alllist?.map((item, index) => {
                    return (
                      <DraggableItem
                        key={item?._id}
                        item={item}
                        index={index}
                        moveItem={moveItem}
                      />
                    );
                  })
                ) : (
                  <div className="data-not-found">
                    <p>No Data Found</p>
                  </div>
                )}
              </DndProvider>

            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={state?.totalpagination}
        paginationevent={paginationevent}
      />
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"Faq"}
        Id={idvalue}
        deleteuser={deleteemployee}
      />
    </Layout>
  );
}
