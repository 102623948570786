import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { adminpasswordresetAction, checkotpAction } from "../Redux/Action/Authaction";
import { useFormik } from "formik";
import * as Yup from 'yup'

export default function Resetpassword() {
    const {id}=useParams();
    const dispatch=useDispatch();
  
    var idvalue = Decryptedid(atob(id));
  
    const formik=useFormik({
      initialValues:{
        password:"",
        confirmpassword:"",
       
      },
      validationSchema:Yup.object().shape({
        password: Yup.string().required('Password is required').min(5, 'Your password is too short.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
        confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
       
      }),
      onSubmit:(values)=>{
        values.id=idvalue;
        dispatch(adminpasswordresetAction(values))
  
      }
    })
  return (
    <div>
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="logo">
          <img src={require("../Assets/Images/Logo.svg").default} />
          </div>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className="box-center">
        <Col lg={12} className="m-auto">
          <div className="login-cmn-box">
            <div className="login-box-inner-wrap">
              <h2 className="text-center">Password Reset</h2>
              <Form onSubmit={formik.handleSubmit}>
                <div className="form-set">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>New password</Form.Label>
                    <Form.Control type="text" placeholder="Type Here"   name="password" onBlur={formik.handleBlur}
                    onChange={formik.handleChange}

                    />
                     {formik.errors.password && <div className="errors">{formik.errors.password}</div>}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control type="text" placeholder="Type Here"   name="confirmpassword" onBlur={formik.handleBlur}
                    onChange={formik.handleChange}

                    />
                     {formik.errors.confirmpassword && <div className="errors">{formik.errors.confirmpassword}</div>}
                  </Form.Group>
                </div>
                <button
               
                  variant="primary"
                  type="submit"
                  className="submit"
                >
                   Update password
                </button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  )
}
