import React from "react";
import ReactPaginate from "react-paginate";
export default function Pagination({ totalpagination, paginationevent }) {
  const handlePageClick = (event) => {
    console.log(event.selected);
    paginationevent(event.selected + 1);
  };
  return (
    <div className="pagination-wrapper next-btn-fix">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={totalpagination}
        previousLabel={"previous"}
        renderOnZeroPageCount={null}
    
      />
    </div>
  );
}
