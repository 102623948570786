import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  addandupdateblogAction,
  blogimageAction,
} from "../Redux/Action/AdminstratorAction";

export default function CreateBlog() {
  const [file, setFile] = useState();
  const [fileshow, setFileShow] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    blogtitle: Yup.string().required("Blog title is required"),
    coverimage: Yup.string().required("Pdf is required")
      .matches(/\.pdf$/, "Only PDF files are allowed"),
    publishstatus: Yup.string(),
    publishnewtab: Yup.string(),
  });

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    let formdata = new FormData();
    formdata.append("blogimage", e.target.files[0]);

    dispatch(blogimageAction(formdata)).then(function (data) {
      console.log(data, "datadata");
      formik.setFieldValue(
        "coverimage",
        process.env.REACT_APP_IMAGE_URL + data?.payload?.filename,
        { strict: false }
      );

      setFileShow(process.env.REACT_APP_IMAGE_URL + data?.payload?.filename);
    });
  }

  const [editorHtml, setEditorHtml] = useState("");

  const handleChangee = (html) => {
    setEditorHtml(html);

    formik.setFieldValue("content", editorHtml, { strict: false });
  };

  const formik = useFormik({
    initialValues: {
      blogtitle: "",
      coverimage: "",
      publishstatus: 0,
      publishnewtab: 0,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      values.coverimage = fileshow;
      dispatch(addandupdateblogAction(values)).then(function () {
        navigate("/blog");
      });
    },
  });


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Blog</h2>
          </div>
        </div>
        <hr />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <div className="product-cmn-tab">
            <Row>
              <Col lg={4}>
                <div className="product-tab-left">
                  <Link to="/blog">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="42"
                      viewBox="0 0 40 42"
                      fill="none"
                    >
                      <path
                        d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                        fill="#40413A"
                      />
                    </svg>{" "}
                    Back to previous page
                  </Link>
                </div>
              </Col>
              <Col
                lg={8}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={8}>
              <div className="customer-form-new">
                <h2>BASIC DETAILS</h2>
                <hr className="cmn-border" />
                <div className="customer-form-inner">
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Blog Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Here"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="blogtitle"
                          value={formik?.values?.blogtitle}
                        />
                        {formik.touched.blogtitle && formik.errors.blogtitle && (
                          <div className="errors">
                            {formik.errors.blogtitle}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={12}>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Upload Pdf</Form.Label>

                          <input
                            type="file"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            name="coverimage"
                            accept=".pdf"
                          />
                          <a
                            target="_blank"
                            class="view_pdf"
                            href={fileshow == "" ? file : fileshow}
                          >
                            {" "}
                            View pdf{" "}
                          </a>

                          {formik.touched.coverimage && formik.errors.coverimage && (
                            <div className="errors">
                              {formik.errors.coverimage}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              {" "}
              <div className="customer-form-new">
                <h2>PUBLISH STATUS</h2>
                <hr className="cmn-border" />
                <div className="publish-status">
                  <Form.Select
                    aria-label="Default select example"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="publishstatus"
                    value={formik.values.publishstatus}
                  >
                    <option value="0">Draft</option>
                    <option value="1">Publish</option>
                  </Form.Select>
                  {formik.submitCount > 0 && formik.errors.publishstatus && (
                    <div className="errors">{formik.errors.publishstatus}</div>
                  )}

                  <div className="cmn-btn mt-4">
                    <button type="submit">SAVE</button>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col lg={8} className="mt-4 mb-4">
            <div className="customer-form-new">
              <h2>CONTENT</h2>
              <hr className="cmn-border" />
              <ReactQuill
                theme="snow" // 'snow' is the default theme
                value={editorHtml}
                onChange={handleChangee}
                style={{ height: "400px" }}
              />
            </div>
          </Col> */}
          </Row>
        </Container>
      </form>
    </Layout>
  );
}
