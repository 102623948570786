import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { donationlistAction } from "../Redux/Action/AdminstratorAction";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useState } from "react";
import moment from "moment";

export default function Donations() {
  const { id } = useParams();

  const dispatch = useDispatch();
  var idvalue = Decryptedid(atob(id));
  const [pageno, setPageno] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  useEffect(() => {
    dispatch(
      donationlistAction({
        userId:idvalue,
        perpage: perpage,
        pageno: pageno,
        
       
      })
    );
    
  }, [idvalue]);


 console.log(idvalue,"idvalueidvalueidvalue")

 
 const donationlist = useSelector((state) => state.Administrator.donationlist);
 var ammount=0;
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Donations</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center px-0"
            >
              <div className="cmn-btn tab">
                <Link to={"/user-profile/"+id}>Overview</Link>
                <Link to={"/donations/"+id} className="ms-2 active">
                  Donations
                </Link>
                <Link to={"/volunteer-logs/"+id} className="ms-2">
                  Volunteer Log
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row>
          <Col xxl={4} xl={4} lg={4}>
            <div className="dash-inner-boxes mb-4">
              <h6>Donations</h6>
              {
                
                donationlist?.donationlist?.map(function(object ,i){

                  return(
                    <h4>{(ammount+=object?.amount)?.toLocaleString('en-US', {
                style: 'currency',
                currency: object?.currency,
              })}</h4>
                  )
                })
              }
             
              <p>10% increase from last month.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Type</th>
                <th>comment </th>
                <th>Dedicated To</th>
              </tr>
            </thead>
            <tbody>

            {
              donationlist?.donationlist?.map(function(object ,i){
                return(
                  <tr>
                  <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>

                <td> {moment(object?.createdAt).format("MM/DD/YYYY")}</td>
                <td>{object?.amount?.toLocaleString('en-US', {
                style: 'currency',
                currency: object?.currency,
              })}</td>
                <td>{object?.donation_type==0?"Give once":"Monthly"}</td>

                <td>{object?.comments }</td>
                <td>{object?.dedicate_to }</td>
              </tr>
                )
              })
            }
             
              
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
