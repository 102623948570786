import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";

import Dashboard from "./pages/Dashboard";
import Administrators from "./pages/Administrators";
import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";
import AddProperty from "./pages/AddProperty";

import DeletedUser from "./pages/DeletedUser";

import UserActive from "./pages/UserActive";
import AccountActivation from "./pages/AccountActivation";
import ManageOrders from "./pages/ManageOrders";

import UserProfile from "./pages/UserProfile";
import MailingList from "./pages/MailingList";
import ManageServices from "./pages/ManageServices";
import AddServices from "./pages/AddServices";
import ManageEvent from "./pages/ManageEvent";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";

import CreateMail from "./pages/CreateMail";
import Clubs from "./pages/Clubs";
import Faqs from "./pages/Faqs";

import VolunteerManagement from "./pages/VolunteerManagement";
import VolunteerLogs from "./pages/VolunteerLogs";
import CreateEvent from "./pages/CreateEvent";
import ManageEmployees from "./pages/ManageEmployees";
import CreateEmployee from "./pages/CreateEmployee";
import RegisteredInterest from "./pages/RegisteredInterest";
import AllJobs from "./pages/AllJobs";
import CreateJob from "./pages/CreateJob";
import AllLinks from "./pages/AllLinks";
import CreateLink from "./pages/CreateLink";
import CreateFaq from "./pages/CreateFaq";
import Donations from "./pages/Donations";
import ViewEvent from "./pages/ViewEvent";
import ViewEmployee from "./pages/ViewEmployee";
import ResetOtp from "./pages/ResetOtp";
import EditAdmin from "./pages/EditAdmin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resetpassword from "./pages/Resetpassword";
import Blog from "./pages/Blog";
import CreateBlog from "./pages/CreateBlog";
import EditBlog from "./pages/EditBlog";
import EditFaq from "./pages/EditFaq";
import EditJob from "./pages/EditJob";
import Editlinks from "./pages/Editlinks";
import VolunteerForm from "./pages/VolunteerForm";
import LogsView from "./pages/LogsView";

import VolenteerCompletedform from "./pages/VolenteerCompletedform";

function App() {
  const isAuthenticated = localStorage.getItem("accessToken");

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/volunteer-form/:name/:date/:user_id" element={<VolunteerForm />} />
          {!isAuthenticated && (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/login-otp/:id" element={<LoginOtp />} />
              <Route path="/ResetOtp/:id" element={<ResetOtp />} />
              <Route path="/Resetpassword/:id" element={<Resetpassword />} />
              <Route path="/reset-otp/:id" element={<ResetOtp />} />
              <Route path="/Forgot-Password" element={<ForgotPassword />} />
            </>
          )}

          {isAuthenticated && (
            <>
              <Route path="*" element={<Navigate to="/Dashboard" />} />
            </>
          )}

          <Route path="/faqs" element={<Faqs />} />
          <Route path="/CreateMail" element={<CreateMail />} />
          <Route path="/edit-admin/:id" element={<EditAdmin />} />
          <Route path="/Clubs" element={<Clubs />} />
          <Route path="/Administrators" element={<Administrators />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />

          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/user-profile/:id" element={<UserProfile />} />

          <Route path="/Supportchat" element={<Supportchat />} />
          <Route path="/CreateAdmin" element={<CreateAdmin />} />
          <Route path="/Add-property" element={<AddProperty />} />
          <Route path="/deleted-user" element={<DeletedUser />} />

          <Route path="/UserActive" element={<UserActive />} />
          <Route path="/AccountActivation" element={<AccountActivation />} />
          <Route path="/ManageOrders" element={<ManageOrders />} />
          <Route path="/ManageServices" element={<ManageServices />} />
          <Route path="/AddServices" element={<AddServices />} />
          <Route path="/manage-event" element={<ManageEvent />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />

          <Route path="/MailingList" element={<MailingList />} />

          <Route
            path="/volunteer-management"
            element={<VolunteerManagement />}
          />
          <Route path="/volunteer-logs/:id" element={<VolunteerLogs />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/manage-employees" element={<ManageEmployees />} />
          <Route path="/create-employee" element={<CreateEmployee />} />
          <Route path="/registered-interest" element={<RegisteredInterest />} />
          <Route path="/allJobs" element={<AllJobs />} />
          <Route path="/create-job" element={<CreateJob />} />
          <Route path="/edit-job/:id" element={<EditJob />} />
          <Route path="/edit-link/:id" element={<Editlinks />} />
          <Route path="/all-links" element={<AllLinks />} />
          <Route path="/create-link" element={<CreateLink />} />
          <Route path="/create-faq" element={<CreateFaq />} />
          <Route path="/edit-faq/:id" element={<EditFaq />} />
          <Route path="/donations/:id" element={<Donations />} />
          <Route path="/view-event/:id" element={<ViewEvent />} />
          <Route path="/view-employee/:id" element={<ViewEmployee />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/edit-blog/:id" element={<EditBlog />} />
          <Route path="/view-volunteer-log/:id" element={<LogsView />} />
          <Route path="/completed-form/:id" element={<VolenteerCompletedform />} />
          
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
