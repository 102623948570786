import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addandupdateblogAction, blogdetailsAction, blogimageAction } from "../Redux/Action/AdminstratorAction";
import { Decryptedid } from "../Util/BcruptEncyptid";

export default function EditBlog() {
  const [file, setFile] = useState();
  const [fileshow, setFileShow] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    // Fetch blog details and update form values
    dispatch(blogdetailsAction({ id: idvalue })).then((data) => {
      if (data.payload) {
        const details = data.payload;
        formik.setValues({
          blogtitle: details.blogtitle || "",
          coverimage: details.coverimage || "",
          publishstatus: details.publishstatus || 0,
          publishnewtab: details.publishnewtab || 0,
          content: details.content || "",
        });
        setFileShow(details.coverimage || "");
        setEditorHtml(details.content || "");
      }
    });
  }, [idvalue, dispatch]);

  function handleChange(e) {
    if (e.target.files?.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      let formdata = new FormData();
      formdata.append("blogimage", e.target.files[0]);

      dispatch(blogimageAction(formdata)).then((data) => {
        formik.setFieldValue("coverimage", process.env.REACT_APP_IMAGE_URL + data?.payload?.filename);
        setFileShow(process.env.REACT_APP_IMAGE_URL + data?.payload?.filename);
      });
    }
  }

  const handleQuillChange = (html) => {
    setEditorHtml(html);
    formik.setFieldValue("content", html);
  };

  const formik = useFormik({
    initialValues: {
      blogtitle: "",
      coverimage: "",
      publishstatus: 0,
      publishnewtab: 0,
      content: "",
    },
    validationSchema: Yup.object({
      blogtitle: Yup.string().required("Blog title is required"),
      coverimage: Yup.string(),
      publishstatus: Yup.string(),
      publishnewtab: Yup.string(),
    }),
    onSubmit: (values) => {
      values.id = idvalue;
      values.coverimage = fileshow;
      dispatch(addandupdateblogAction(values)).then(() => {
        navigate("/blog");
      });
    },
  });

   console.log("fileshow", fileshow)
  console.log("file", file)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Edit Blog</h2>
        </div>
        <hr />
      </div>
      <form onSubmit={formik.handleSubmit}>
      <Container fluid>
          <div className="product-cmn-tab">
            <Row>
              <Col lg={4}>
                <div className="product-tab-left">
                  <Link to="/blog">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="42"
                      viewBox="0 0 40 42"
                      fill="none"
                    >
                      <path
                        d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                        fill="#40413A"
                      />
                    </svg>{" "}
                    Back to previous page
                  </Link>
                </div>
              </Col>
              <Col
                lg={8}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={8}>
              <div className="customer-form-new">
                <h2>BASIC DETAILS</h2>
                <hr className="cmn-border" />
                <div className="customer-form-inner">
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Blog Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Here"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="blogtitle"
                          value={formik.values.blogtitle}
                        />
                        {formik.touched.blogtitle && formik.errors.blogtitle && (
                          <div className="errors">{formik.errors.blogtitle}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Upload Pdf</Form.Label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          name="coverimage"
                          accept=".pdf"
                        />
                        <p className="mt-2">
                          <b>Selected file: </b> {fileshow || file}
                        </p>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="view_pdf"
                          href={fileshow || file}
                        >
                          View pdf
                        </a>
                        {formik.touched.coverimage && formik.errors.coverimage && (
                          <div className="errors">{formik.errors.coverimage}</div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="customer-form-new">
                <h2>PUBLISH STATUS</h2>
                <hr className="cmn-border" />
                <Form.Select
                  aria-label="Default select example"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="publishstatus"
                  value={formik.values.publishstatus}
                >
                  <option value="0">Draft</option>
                  <option value="1">Publish</option>
                </Form.Select>
                {formik.touched.publishstatus && formik.errors.publishstatus && (
                  <div className="errors">{formik.errors.publishstatus}</div>
                )}
                <div className="cmn-btn mt-4">
                  <button type="submit">SAVE</button>
                </div>
              </div>
            </Col>
            {/* <Col lg={8} className="mt-4 mb-4">
              <div className="customer-form-new">
                <h2>CONTENT</h2>
                <hr className="cmn-border" />
                <ReactQuill
                  theme="snow"
                  value={editorHtml}
                  onChange={handleQuillChange}
                  style={{ height: "400px" }}
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </form>
    </Layout>
  );
}
