import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { LoginAction } from "../Redux/Action/Authaction";
export default function Login() {
  const dispatch=useDispatch();

  const formik=useFormik({
    initialValues:{
      email:"",
      password:""
    },
    validationSchema:Yup.object().shape({
      email:Yup.string().email("Please enter a valid email").required('Email is required'),
      password:Yup.string().required('Password is required')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      dispatch(LoginAction(values))

    }
  })


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Type Here" name="email" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                       />
                        {formik.errors.email && <div className="errors">{formik.errors.email}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Type Here"   name="password" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}

                      />
                       {formik.errors.password && <div className="errors">{formik.errors.password}</div>}
                    </Form.Group>
                  </div>
                  <button
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </button>
                  <Link to="/Forgot-Password" className="forgot">
                    Forgot Password?
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
