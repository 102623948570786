import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import {
  deletejobAction,
  gelistsexportjobAction,
  getjoblistAction,
} from "../Redux/Action/AdminstratorAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Encryptedid } from "../Util/BcruptEncyptid";
import DeleteComponent from "../Components/DeleteComponent";
import DownloadComponent from "../Components/DownloadComponent";

export default function AllJobs() {
  const [idvalue, setIdvalue] = useState("");
  const [show, setShow] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getjoblistAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const joblist = useSelector((state) => state.Administrator.joblist);

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const deletejob = () => {
    dispatch(
      deletejobAction({
        id: idvalue,
      })
    ).then(function() {
      navigate("/allJobs");
      dispatch(
        getjoblistAction({
          perpage: perpage,
          pageno: pageno,
          tab: tab,
          serachkeyval: serachkeyval,
        })
      );
      setShow(false);
    });
  };
  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };




  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Jobs</h2>
        </div>
        <hr />

        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
           

              <Col className="d-flex justify-content-end align-items-center">
              <div className="cmn-btn ml-1 me-2">
             

                  <DownloadComponent  action={gelistsexportjobAction} serachkeyval={serachkeyval} />
                  
                </div>
                <div className="cmn-btn">
                  <Link to="/create-job">Create </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (joblist?.Totaljob > 10 ? rangeEnd : joblist?.Totaljob) +
                    " of " +
                    joblist?.Totaljob?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Type</th>

                {/* <th>Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {joblist?.alllist?.map(function(jobobject, i) {
                return (
                  <tr>
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td style={{ cursor:"pointer" }}
                      onClick={() => {
                        navigate("/edit-job/" + Encryptedid(jobobject?._id));
                      }}
                    >

                      {jobobject?.title}
                    </td>
                    <td>
                      {" "}
                      <p className="dots-set">{jobobject?.job_type}</p>
                    </td>

                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setIdvalue(jobobject?._id);
                          setShow(true);
                        }}
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"JOB"}
        Id={idvalue}
        deleteuser={deletejob}
      />

    </Layout>
  );
}
