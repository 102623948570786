import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { usePDF } from 'react-to-pdf';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { checkincheckoutsaveAction, employeeimageAction, imagesaveAction, volunteerlogdetailsAction } from "../Redux/Action/AdminstratorAction";
import Signature from "signature_pad";
import { FaSave, FaUndo, FaRedo, FaEraser, FaSignature } from "react-icons/fa";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";

import moment from "moment";
export default function VolenteerCompletedform() {
  const dispatch=useDispatch();

  const { toPDF, targetRef } = usePDF({filename: new Date()+'.pdf'});
  const [object,Setobject]=useState({})
  const {id}=useParams();
  useEffect(()=>{
  dispatch(volunteerlogdetailsAction({
      id:id
  })).then(function(data){
      console.log(data.payload.
          volunteerlistdata.details
          )
          Setobject(data.payload.
              volunteerlistdata.details)

  })
  },[id])


  return (
  

  
    <div className="container" >
    <div className="top-bar-btns">
        <Link to={"/view-volunteer-log/"+id}>Back</Link>
        <button onClick={() => toPDF()}>Download</button>
    </div>
      <div className="volunteer-form" ref={targetRef}  style={{padding:"30px 100px"}}>
        <img src={require("../Assets/Images/Logo.svg").default} />
        <h4>Expectations and Boundaries</h4>
        <p>
          Here at The Home, we have the honor to step alongside children and
          their families on the path towards restoration and reconciliation. To
          do this well, we must be mindful of what has happened in their past,
          their current present, and the future. With that in mind, we have
          created the following boundaries to prioritize safety and respect of
          the children, their families, campus, staff, our volunteers, and CHOL
          as an organization. We ask that you honor these boundaries any time
          you visit our campus or interact with the children we serve. If you
          have any questions at all, please take time to clarify with the
          Community Engagement Coordinator.
        </p>

        <ol>
          <li>
            If a child begins to talk about their biological family, friends,
            previous school, etc., we want to allow or encourage conversation.
            It is healthy and good for kids to think of their positive memories.
            For example, “Your mom bought you that dress? I love the color!” Or
            “You used to go to Walmart and buy your favorite snack with your
            grandma… I bet that was fun!”
          </li>
          <li>
            If a child ever begins to share or disclose something that has
            happened to them, we ask that you bring a staff into the
            conversation. A way to do this naturally may be, “Thank you for
            telling me, let’s let Ms. Smith talk with us too.” We ask that you
            include staff and not have the conversation 1:1 because our staff
            are trained to respond appropriately, know the kiddos well, and know
            what steps to take, if any, are needed. It is important to remember
            that is our job to believe the child and report it to the
            appropriate person, not to determine what is true or false
          </li>
          <li>
            Some people may be sensitive to touch from others, especially
            strangers. Others may actively seek out touch from others. Please
            ALWAYS ask permission before touching someone- a hand on the
            shoulder, a hug, even a simple brush against someone’s arm. This is
            something we ask the children to do as well. All touch should be
            respectful, appropriate in length, and only after consent is given
            from both parties. Any hugs should be from the side only.
          </li>
          <li>
            Please refrain from using any vulgar, coarse, or rude language. We
            want to be positive influences. Please also refrain from “pet names”
            or nicknames such as “sweetheart, love, etc.”
          </li>
          <li>
            All cultures and backgrounds are honored and respected here- this
            includes and is not limited to ethnicity, race, religion, financial
            status, language, etc.
          </li>
          <li>
            Please do not wander around campus or rooms within the building you
            are working in.
          </li>
          <li>
            You will never be left alone with a child. If you are ever asked to
            violate this boundary or find yourself in this situation, rejoin the
            group immediately.
          </li>
          <li>
            No gifts may be given directly from volunteer to child or cottage-
            these must all be approved through the Volunteer Coordinator first.
          </li>
          <img src={require("../Assets/Images/Logo.svg").default} />
          <li>
            Please be mindful not to include children in any photographs taken.
          </li>
          <li>
            All volunteers are expected to check in when arriving on campus and
            to check out when leaving campus.
          </li>
        </ol>
        <p>
          Visitor and Volunteer Contact: Morgan Maret 806-680-3199,
          mmaret@childshome.org
        </p>
        <p>
          By signing, you acknowledge that you have been notified of these
          boundaries and agree to uphold and honor all expectations of the
          volunteer role.
        </p>

        <div className="acknowledge">
        <div className="row">
            <div className="col-lg-12">
            <div className="signature-pad1">
        <img src={process.env.REACT_APP_IMAGE_URL+"signature_file/"+object?.signature1} />
        
              </div>
              <h3>Signature</h3>
            </div>
            <div className="col-lg-12">
            <label> { object?.name}</label>
                       
              <h3>Volunteer’s Name (Print)</h3>
            </div>
            <div className="col-lg-12">
            <label> { moment(object?.intime).format('DD/MM/YYYY')}</label>
              <h3>Date</h3>
            </div>
          </div>
        </div>
        <h2>
          REPORTING CHILD ABUSE, NEGLECT, AND EXPLOITATION TO CHILD ABUSE
          HOTLINE AND DESIGNATED ADMINISTRATOR
        </h2>
        <p style={{ marginTop:"100px" }}>
          Legally, what child abuse, neglect and exploitation are:
          <br />
          In Texas, the definitions of child abuse and neglect include specific
          acts or omissions by a person responsible for a child’s care, custody,
          or welfare. Here are important legal definitions from Section 261.001
          of the Texas Family Code.
        </p>
        <h5>“Abuse“ includes the following acts or omissions by a person:</h5>
        <ul>
          <li>
            Mental or emotional injury to a child that results in an observable
            and material impairment in the child’s growth, development, or
            psychological functioning;
          </li>
          <li>
            Causing or permitting the child to be in a situation in which the
            child sustains a mental or emotional injury that results in an
            observable and material; impairment in the child’s growth,
            development, or psychological functioning
          </li>
          <li>
            Physical injury that results in substantial harm to the child, or
            the genuine threat of substantial harm from physical injury to the
            child, including an injury that is at variance with the history or
            explanation given and excluding an accident or reasonable discipline
            by a parent, guardian, or managing or possessory conservator that
            does not expose the child to a substantial risk of harm;
          </li>
          <li>
            Failure to make a reasonable effort to prevent an action by another
            person that results in physical injury that results in substantial
            harm to the child;
          </li>
          <li>
            Sexual conduct harmful to a child’s mental, emotional, or physical
            welfare, including conduct that constitutes the offense or
            continuous sexual abuse of a young child or disabled individual
            under Section 21.02, Penal Code, indecency with a child under
            Section 21.11, Penal Code, sexual assault under Section 22.011,
            Penal Code, or aggravated sexual assault under Section 22.021, Penal
            Code;
          </li>
          <li>
            Failure to make a reasonable effort to prevent sexual conduct
            harmful to a child;
          </li>
          <li>
            Compelling or encouraging the child to engage in sexual conduct as
            defined by Section 43.01, Penal Code, including compelling or
            encouraging the child in a manner that constitutes an offense of
            trafficking or persons under Section 20A.02(a)(7) or (8), Penal
            Code, solicitation of prostitution under Section 43.021, Penal Code,
            or compelling prostitution under Section 43.05(a)(2), Penal Code;
          </li>
          <li>
            Causing, permitting, encouraging, engaging in, or allowing the
            photographing, filming, or depicting of the child if the person knew
            or should have known that the resulting photograph, film, or
            depiction of the child is obscene as defined by Section 43.21, Penal
            Code, or pornographic;
          </li>
          <li>
            The current use by a person of a controlled substance as defined by
            Chapter 481, Health and Safety Code, in a manner or to the extent
            that the use results in physical, mental, or emotional injury to the
            child;
          </li>
          <li>
            Causing, expressly permitting, or encouraging a child to use a
            controlled substance as defined by Chapter 481, Health and Safety
            Code;
          </li>
          <li>
            Causing, permitting, encouraging, engaging in, or allowing a sexual
            performance by a child as defined by Section 43.25, Penal Code
          </li>
          <li>
            Knowingly causing, permitting, encouraging, engaging in, or allowing
            a child to be trafficked in a manner punishable as an offense under
            Section 20A.02(a)(5), (6), (7), or (8), Penal Code, or the failure
            to make a reasonable effort to prevent a child from being trafficked
            in a manner punishable as an offense under any of those sections; or
          </li>
          <li>Forcing or coercing a child to enter into a marriage.</li>
        </ul>
        <h5>“Neglect“ includes:</h5>
        <ul>
          <li>
            Leaving of a child in a situation where the child would be exposed
            to a substantial risk of physical or mental harm, without arranging
            for necessary care for the child, and the demonstration of an intent
            not to return by a parent, guardian, or managing or possessory
            conservator of the child.
          </li>
          <li>The following acts or omissions by a person:</li>
          <li>
            Placing a child in or failing to remove a child from a situation
            that a reasonable person would realize requires judgement or actions
            beyond the child’s level of maturity, physical condition, or mental
            abilities and that result in bodily injury or a substantial risk of
            immediate harm to the child;
          </li>
          <li>
            Failing to seek, obtain, or follow through with medical care for a
            child, with the failure resulting in or presenting a substantial
            risk of death, disfigurement, or bodily injury; or with the failure
            resulting in an observable and material impairment to the growth,
            development, or functioning of the child;
          </li>
          <li>
            The failure to provide a child with food, clothing, or shelter
            necessary to sustain the life or health of the child, excluding
            failure caused primarily by financial inability unless relief
            services had been offered and refused;
          </li>
          <li>
            Placing a child in or failing to remove the child from a situation
            in which the child would be exposed to a substantial risk of sexual
            conduct harmful to the child;
          </li>
          <li>
            Placing a child in or failing to remove the child from a situation
            in which the child would be exposed to an immediate danger of sexual
            conduct harmful to the child, or;
          </li>
          <li>
            Placing a child in or failing to remove the child from a situation
            in which the child would be exposed to acts or omissions that
            constitute abuse under Subdivision (1) (E), (F), (G), (H), or (K)
            committed against another child.
          </li>
          <li>
            The failure by the person responsible for a child’s care, custody,
            or welfare to permit the child to return to the child’s home without
            arranging for the necessary care for the child after the child has
            been absent from the home for any reason, including having been in
            residential placement or having run away.
          </li>
          <li>
            A negligent act or omission by an employee, volunteer, or other
            individual working under the auspices of a facility or program,
            including failure to comply with an individual treatment plan, plan
            of care, or individualized service plan, that causes or may cause
            substantial emotional harm or physical injury to, or the death of, a
            child served by the facility or program as further described by rule
            or policy; and
          </li>
        </ul>
        <h5>“Child Exploitation“ includes:</h5>
        <ul>
          <li>
            Child exploitation is the act of using a minor child for profit,
            labor, sexual gratification, or some other personal or financial
            advantage. Child exploitation often results in cruel or harmful
            treatment of the child, as the activities he or she may be forced to
            take part in can cause emotional, physical, and social problems.
          </li>
        </ul>
        <div className="conjust-sec">
          <h5>
            “Person responsible for a child’s care, custody, or welfare” means a
            person who traditionally is responsible for a child’s care, custody,
            or welfare, including:
          </h5>
          <ol>
            <li>
              A parent, guardian, managing or possessory conservator, or foster
              parent of the child.
            </li>
            <li>
              A member of the child’s family or household as defined by Chapter
              71.
            </li>
            <li>A person with whom the child’s parent cohabit.</li>
            <li>School personnel or a volunteer at the child’s school.</li>
            <li>
              Personnel or volunteer at a public or private child-care facility
              that provides services for the child or at a public or private
              residential institution or facility where the child resides.
            </li>
            <li>
              An employee, volunteer, or other person working under the
              supervision of a licensed or unlicensed child-care facility,
              including a family home, residential child-care facility,
              employer-based day-care facility, or shelter day-care facility, as
              those terms are defined in Chapter 42, Human Resources Code.
            </li>
          </ol>
          <h5>
            What should you do when you suspect child abuse, neglect, or
            exploitation?
          </h5>
          <h5>
            Immediately report suspected abuse to DFPS Hotline, AND either
          </h5>
          <ul>
            <li>
              CPA and Emergency Shelter Administrator, Ben Witt, (719) 651-5092
            </li>
            <li>
              GRO Residential Campus Administrator, Theresa Wilson, (806)
              401-1738
            </li>
          </ul>
          <ol>
            <li>
              The law requires that you make a report if you have reason to
              believe or suspect that abuse or neglect has occurred.
            </li>
            <li>
              Tell the child you believe him/her and offer comfort and
              assurances. It is not your job to provide or decide if abuse has
              taken place. That is up to Licensing, CPS, law enforcement, and/or
              courts
            </li>
            <li>
              Protect the child. If the child is in danger of physical/emotional
              harm and needs protection, call the above administrator(s) or
              on-call.
            </li>
            <li>
              You will also need to complete an incident report in Children’s
              Home Extended Reach case management system.
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <h5>Report Abuse to DFPS:</h5>
            <ul>
              <li>By phone: 1-800-252-5400</li>
              <li>
                Online: Texas Abuse Hotline (Scan QR code to the right) You can
                call the Abuse Hotline toll-free 24 hours a day, 7 days a week,
                nationwide, or report with secure website and get a response
                within 24 hours.
              </li>
            </ul>
            <p>
              DFPS cannot accept email reports of suspected abuse or neglect.
            </p>
          </div>
          <div className="col-lg-4">
            <img
              className="bar-scanner"
              src={require("../Assets/Images/barscanner.png")}
            />
          </div>
        </div>
        <h5>Anonymous Reports of Child Abuse and Neglect</h5>
        <p>
          <italic>
            Effective September 1, 2023, DFPS cannot accept anonymous reports of
            abuse and neglect involving children and their families. Reporters
            must provide a first and last name and a home or business phone
            number to meet new reporting requirements. Anonymous reports of
            child abuse and neglect may be made to local and state law
            enforcement agencies instead. Reporters’ names are confidential by
            law.
          </italic>
        </p>

        <h5>
          I have read the information and instructions regarding reporting of
          child abuse, neglect, and exploitation. I understand this and agree to
          abide by these instructions.
        </h5>
        <div className="acknowledge">
        <div className="row">
            <div className="col-lg-12">
            <div className="signature-pad1">
        <img src={process.env.REACT_APP_IMAGE_URL+"signature_file/"+object?.signature2} />
        
              </div>
              <h3>Signature</h3>
            </div>
            <div className="col-lg-12">
            <label> { object?.name}</label>
                       
              <h3>Volunteer’s Name (Print)</h3>
            </div>
            <div className="col-lg-12">
                <label> { moment(object?.intime).format('DD/MM/YYYY')}</label>
                      
              <h3>Date</h3>
            </div>
          </div>
        </div>
        <h2>
          CHILDREN’S HOME OF LUBBOCK
          <br /> STATEMENT OF CONFIDENTIALITY
        </h2>
        <p>
          By signing below, I agree to the following Statement of
          Confidentiality:
        </p>
        <p>
          While volunteering with the Children’s Home of Lubbock, you will have
          access to and gain knowledge of confidential and proprietary
          information belonging to the organization. Maintaining confidentiality
          is an important part of working at the Children’s Home of Lubbock.
          Information contained in an employee’s personnel file, a child’s file,
          a donor’s file, or issues regarding other clients of the Children’s
          Home must be treated in a confidential manner.
        </p>
        <p>
          ALL employees, on campus subcontracting therapists, subcontractors,
          consultants, student interns, volunteers acting as staff, or sponsors
          are directed to hold in confidence all information obtained in the
          course of professional services and maintain confidentiality regarding
          the children of this agency. Confidential information regarding the
          employees, children and families, donors, or other clients should not
          be shared with the media including the internet and any personal web
          pages or blogs. Failure to properly handle confidential information
          constitutes terms for disciplinary action.
        </p>
        <div className="acknowledge">
        <div className="row">
            <div className="col-lg-12">
            <div className="signature-pad1">
        <img src={process.env.REACT_APP_IMAGE_URL+"signature_file/"+object?.signature3} />
        
              </div>
              <h3>Signature</h3>
            </div>
            <div className="col-lg-12">
            <label> { object?.name}</label>
                       
              <h3>Volunteer’s Name (Print)</h3>
            </div>
            <div className="col-lg-12">
            <label> { moment(object?.intime).format('DD/MM/YYYY')}</label>
                      
              <h3>Date</h3>
            </div>
          </div>
        </div>
        <img src={require("../Assets/Images/Logo.svg").default} />
        <p>
          Hello! We are so thankful to have you on our team to serve the
          community of West Texas. We know that there are different
          opportunities to serve the Lubbock community and are grateful that you
          would choose to step alongside the kiddos we are entrusted with as
          they heal, play, and build new memories. Volunteers are a valued and
          integral part of what we do at Children’s Home of Lubbock. As you join
          us on campus, this document serves to share a little bit more about
          what we do and outlines some expectations and best practices of our
          organization. We can’t wait to work with you soon!
        </p>
        <h5>About Us</h5>
        <p>
          Children’s Home of Lubbock has been a staple of the Lubbock community
          since 1954 and has served over 7,200 abused and neglected children
          throughout our history. The Home’s goal is to be a place for children
          to heal from trauma and work towards a better future.
        </p>
        <p>
          On any given day, an average of 70 boys and girls are in care at
          Children’s Home whether through our cottages, foster homes, or
          independent living housing. These children come to The Home through
          the Department of Family Protective Services because of suspected
          neglect or abuse. While the court and biological families work through
          each case, the children are cared for by our staff and begin
          Sanctuary, a trauma-informed treatment program.
        </p>
        <p>
          Children’s Home of Lubbock is a faith-based agency, and we believe God
          has been with us every step of the way as we have faced personal and
          legislative trials. We are blessed with a modern, debt-free group care
          campus, and The Home continues to expand as the needs of our children
          and community change.
        </p>
        <h5>What We Do</h5>
        <ul>
          <li>Counseling</li>
          <li>Education & Recreation</li>
          <li>Emergency Shelter & Assessment Center</li>
          <li>Family Success Program</li>
          <li>Foster Care & Adoption Program</li>
          <li>Residential Group Care Program</li>
          <li>Supervised Independent Living</li>
          <li>Transitional Living</li>
        </ul>
        <p>Learn more about our history and what we do at www.childshome.org</p>
    
      </div>
      
   
    </div>
   
  );
}
