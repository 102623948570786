import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import Pagination from "../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { RegInterestListAction, UserlistAction, deleteRegInterestAction } from "../Redux/Action/AdminstratorAction";
import { useEffect } from "react";
import { useState } from "react";
import DeleteComponent from "../Components/DeleteComponent";
import { toast } from "react-toastify";
import moment from "moment";

export default function RegisteredInterest() {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    // Handle range selection
    console.log(ranges);
    setSelectionRange(ranges.selection);
  };

  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [active, setSactive] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [senddateRange, setsenDateRange] = useState({ from: null, to: null });
  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState(false);
  const [idVal, setIdVal] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );
  useEffect(() => {
    dispatch(
      RegInterestListAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
        senddateRange: senddateRange,
        active: active,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval, senddateRange, active]);

  const memberlists = useSelector((state) => state.Administrator?.regIntList);
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const handleShow = (id) => {
    setIsShowModal(true)
    setIdVal(id)
  }

  const handleClose = () => {
    setIsShowModal(false);
    setIdVal("");
  };

  const deletejob = () => {
    dispatch(deleteRegInterestAction(idVal)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message)
        dispatch(
          RegInterestListAction({
            perpage: Number(perpage),
            pageno: pageno,
            tab: tab,
            serachkeyval: serachkeyval,
            senddateRange: senddateRange,
            active: active,
          })
        );
        setIsShowModal(false);
      } else {
        toast.error(res?.payload?.message)
      }

    });
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Registered Interest</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>

        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " - " +
                      (memberlists?.Totaluser > 10
                        ? rangeEnd
                        : memberlists?.Totaluser) +
                      " of " +
                      memberlists?.Totaluser?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${memberlists?.newtoday > 10
                      ? rangeEnd
                      : memberlists?.newtoday
                      } of  ${memberlists?.newtoday?.toLocaleString("en-US")} `;
                  } else if (tab === 2) {
                    return `${rangeStart} - ${memberlists?.activeuser > 10
                      ? rangeEnd
                      : memberlists?.activeuser
                      } of  ${memberlists?.activeuser?.toLocaleString("en-US")} `;
                  } else {
                    return `${rangeStart} - ${memberlists?.inactiveuser > 10
                      ? rangeEnd
                      : memberlists?.inactiveuser
                      } of  ${memberlists?.inactiveuser?.toLocaleString(
                        "en-US"
                      )} `;
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Number</th>
                <th>I am interested in</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {memberlists?.userlists?.map(function (memberobject, i) {
                return (
                  <tr key={i}>
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>

                    <td>
                      <div>
                        {memberobject?.firstName} {memberobject?.lastName}
                      </div>
                    </td>

                    <td>{memberobject?.email}</td>
                    <td>
                      {memberobject?.phoneNumber == undefined
                        ? "---"
                        : memberobject?.phoneNumber}
                    </td>

                    <td>{memberobject?.interesetdetails?.interestName}</td>
                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        type="button" onClick={() => handleShow(memberobject?._id)}
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>


      <DeleteComponent
        show={isShowModal}
        handleClose={handleClose}
        type={"REGINTEREST"}
        Id={idVal}
        deleteuser={deletejob}
      />

      <Pagination
        totalpagination={memberlists?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
