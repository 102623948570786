import React from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { changepasswordAction } from "../Redux/Action/Authaction";
export default function ChangePassword() {
  const dispatch=useDispatch();

  const formik=useFormik({
    initialValues:{
      new_password:"",
      password:"",
      confirmpassword:""
    },
    validationSchema:Yup.object().shape({
      password:Yup.string().required('Password is required'),
       new_password: Yup.string().required('Password is required').min(5, 'Your password is too short.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
      confirmpassword: Yup.string().oneOf([Yup.ref('new_password')], 'Passwords must match')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      dispatch(changepasswordAction(values))

    }
  })
  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>

                <Form className="change-password-form px-5" onSubmit={formik.handleSubmit}>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Old Password"
                        name="password" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}

                      />
                       { formik.submitCount>0 &&  formik.errors.password && <div className="errors">{formik.errors.password}</div>}
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="new_password" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}

                      />
                       { formik.submitCount>0 &&  formik.errors.new_password && <div className="errors">{formik.errors.new_password}</div>}
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmpassword" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}

                      />
                       { formik.submitCount>0 &&  formik.errors.confirmpassword && <div className="errors">{formik.errors.confirmpassword}</div>}
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button type="submit">SAVE</button>
                    </div>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
