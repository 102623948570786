import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createEventAction,
  eventdetailsDataAction,
  eventimageAction,
} from "../Redux/Action/AdminstratorAction";
import { useEffect } from "react";
import { Decryptedid } from "../Util/BcruptEncyptid";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function ViewEvent() {
  const [file, setFile] = useState();
  const [fileshow, setFileShow] = useState("");

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    let formdata = new FormData();
    formdata.append("eventimage", e.target.files[0]);

    dispatch(eventimageAction(formdata)).then(function(data) {
      formik.setFieldValue(
        "image",
        process.env.REACT_APP_IMAGE_URL + data?.payload?.filename,
        { strict: false }
      );

      setFileShow(process.env.REACT_APP_IMAGE_URL + data?.payload?.filename);
    });
  }

  const dispatch = useDispatch();
  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    dispatch(eventdetailsDataAction({ id: idvalue })).then(function(data) {
      if (data.payload) {
        var Details = data.payload;

        for (const [key, value] of Object.entries(Details)) {
          if (key != "_id" && key != "image" && key != "date") {
            formik.setFieldValue(key, value, { strict: false });
          }

          if (key == "date") {
            formik.setFieldValue("date", moment(value).format("YYYY-MM-DD"), {
              strict: false,
            });
          }

          if (key == "image") {
            setFileShow(value);
          }
        }
      }
    });
  }, [idvalue]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      date: "",
      start_time: "",
      end_time: "",
      address: "",
    
      desc: "",
      rsvp: "",
      image: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      date: Yup.string().required("Date is required"),
      start_time: Yup.string().required("Start time is required"),
      end_time: Yup.string().required("End time is required"),
      address: Yup.string().required("Address is required"),
    
      desc: Yup.string().required("Description is required"),
      rsvp: Yup.string().required("Rsvp link is required"),
      image: Yup.string(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.image = fileshow;
      values.id = idvalue;
      dispatch(createEventAction(values)).then(function() {
        navigate("/manage-event");
      });
    },
  });

  console.log(formik.errors,"dfffffffffffffff")

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Event</h2>
            {/* <p>ADMIN</p> */}
          </div>
          {/* <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div> */}
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/manage-event">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <form onSubmit={formik.handleSubmit}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="customer-form-new mt-4 mb-4">
                <div className="customer-form-inner">
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Here"
                          name="title"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.title}
                        />
                        {formik.submitCount > 0 && formik.errors.title && (
                          <div className="errors">{formik.errors.title}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Date</Form.Label>
                        <DatePicker
                          className="currency-out"
                           name="date"
                              selected={formik.values.date}
                              onChange={(date) => {
                                  formik.setFieldValue('date', date);
                              }}
                              dateFormat="MM/dd/yyyy" // Specify the date format here
                              placeholderText="MM/DD/YYYY" // Set the placeholder text
                          />

                        {formik.submitCount > 0 && formik.errors.date && (
                          <div className="errors">{formik.errors.date}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Start Time</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Type Here"
                          name="start_time"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.start_time}
                        />
                        {formik.submitCount > 0 && formik.errors.start_time && (
                          <div className="errors">
                            {formik.errors.start_time}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>End Time</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Type Here"
                          name="end_time"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.end_time}
                        />
                        {formik.submitCount > 0 && formik.errors.end_time && (
                          <div className="errors">{formik.errors.end_time}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Here"
                          name="address"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                        {formik.submitCount > 0 && formik.errors.address && (
                          <div className="errors">{formik.errors.address}</div>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Cost (Optional)</Form.Label>
                        <CurrencyInput
                          className="currency-out"
                          id="input-example"
                          name="cost"
                          placeholder="Please enter a number"
                          value={formik.values.cost}
                          decimalsLimit={2}
                          onBlur={formik.handleBlur}
                          prefix="$"
                          onValueChange={(value, name, values) => {
                            formik.setFieldValue("cost", values.float);
                          }}
                        />
                      </Form.Group>
                    </Col> */}
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="desc"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.desc}
                        />
                        {formik.submitCount > 0 && formik.errors.desc && (
                          <div className="errors">{formik.errors.desc}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>RSVP Link</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Here"
                          name="rsvp"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rsvp}
                        />
                        {formik.submitCount > 0 && formik.errors.rsvp && (
                          <div className="errors">{formik.errors.rsvp}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <div className="image-upload">
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleChange}
                          />
                          <p>Upload Image</p>
                          <img
                            className="preview-img"
                            src={fileshow == "" ? file : fileshow}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={12} className="d-flex justify-content-center">
                      <div className="cmn-btn">
                        <button type="submit">Update</button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </Layout>
  );
}
