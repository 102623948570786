import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { AdminlistAction } from "../Redux/Action/AdminstratorAction";
import { Encryptedid } from "../Util/BcruptEncyptid";
import moment from "moment";
import Pagination from "../Components/Pagination";

export default function Administrators() {
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [tab, setTab] = useState(1);
  const [perpage, setPerpage] = useState(20);
  const [role, setrole] = useState([0, 1, 2]);
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  useEffect(() => {
    dispatch(
      AdminlistAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        role: role,
        serachkeyval: serachkeyval,
      })
    );
  }, [pageno, perpage, tab, role, serachkeyval]);

  const Adminlist = useSelector((state) => state.Administrator.Adminlist);
  const navigate = useNavigate();

  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/edit-admin/" + bcrypt);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const recal = () => {
    dispatch(AdminlistAction());
  };

  const paginationevent = (pag) => {
    setPageno(pag);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setSerachkeyval(serachkey);
    }
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Administration</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>



              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  name="role"
                  onClick={(e) => {
                    setrole(
                      e.target.value == ""
                        ? [0, 1, 2]
                        : [parseInt(e.target.value)]
                    );
                  }}
                >
                  <option value="">Please select Role</option>
                  <option value="0"> Super Admin</option>
                  <option value="1">Admin</option>
                  <option value="2">Developer</option>
                </Form.Select>
              </Col>

              <Col
                xxl={5}
                xl={3}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/CreateAdmin"> CREATE ADMIN</Link>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link
                  to="#"
                  className={tab == 1 && "active-tab"}
                  onClick={() => {
                    setTab(1);
                    setPerpage(20);
                    setPageno(1);
                  }}
                >
                  ALL <span>{Adminlist?.Totaladmin}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(20);
                    setPageno(1);
                  }}
                >
                  SUPER ADMIN <span> {Adminlist?.superadmin}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 3 && "active-tab"}
                  onClick={() => {
                    setTab(3);
                    setPerpage(20);
                    setPageno(1);
                  }}
                >
                  ADMIN <span> {Adminlist?.Admincount}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " - " +
                      (Adminlist?.Totaladmin > 10
                        ? rangeEnd
                        : Adminlist?.Totaladmin) +
                      " of " +
                      Adminlist?.Totaladmin?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${Adminlist?.superadmin > 10
                        ? rangeEnd
                        : Adminlist?.superadmin
                      } of  ${Adminlist?.superadmin?.toLocaleString("en-US")} `;
                  } else {
                    return `${rangeStart} - ${Adminlist?.Admincount > 10
                        ? rangeEnd
                        : Adminlist?.Admincount
                      } of  ${Adminlist?.Admincount?.toLocaleString("en-US")} `;
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option >20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>ID #</th>
                <th>CREATED DATE</th>
                <th>NAME</th>
                <th>ROLE</th>
                <th>PERMISSIONS </th>
                <th>LAST ACTIVE</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {Adminlist?.alllist?.map(function (object_administrator, i) {
                return (
                  <tr
                    key={i}
                    onClick={() => {
                      admindetails(object_administrator?._id);
                    }}
                  >
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td>
                      {moment(object_administrator?.createdAt).format(
                        "MM/DD/YYYY"
                      )}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(object_administrator?.createdAt).format(
                          "HH:mm"
                        )}
                      </span>
                    </td>
                    <td>
                      <div>{object_administrator?.name}</div>
                    </td>
                    <td>
                      {(() => {
                        if (object_administrator?.role === 0) {
                          return "Super Admin";
                        } else if (object_administrator?.role === 1) {
                          return "Admin";
                        } else {
                          return "Developer";
                        }
                      })()}
                    </td>
                    <td>Full Access</td>
                    <td>
                      {moment(object_administrator?.updatedAt).format(
                        "MM/DD/YYYY"
                      )}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(object_administrator?.updatedAt).format(
                          "HH:mm"
                        )}
                      </span>
                    </td>
                    {/* <td>
                    <div
                      className="delete-btn"
                      onClick={() => {
                        setShow(true);
                        // setid(object_administrator?._id);
                      }}
                    >
                      <button type="button">DELETE</button>
                    </div>
                  </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={Adminlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
