import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx/xlsx.mjs';
export default function DownloadComponent({action,serachkeyval}) {
    const dispatch=useDispatch();

    const fetchDataAndExportToExcel = async () => {
        try {
        const response = await dispatch(action({ serachkeyval: serachkeyval}));
        console.log(response);
        
        const allData = response?.payload?.checkinlist
        console.log(allData)
        
        const ws = XLSX.utils.json_to_sheet(allData);
        
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        
        XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
        });
        } catch (error) {
        console.error("Error fetching or exporting data:", error);
        }
        };





  return (
    <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
  )
}
