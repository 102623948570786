import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import {
  arrangeEventListOrderAction,
  deleteeventAction,
  eventlistDataAction,
  gelistsexporteventAction,
} from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import Pagination from "../Components/Pagination";
import DeleteComponent from "../Components/DeleteComponent";
import { Encryptedid } from "../Util/BcruptEncyptid";
import DownloadComponent from "../Components/DownloadComponent";
import { useDraggable } from "react-use-draggable-scroll";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";


export default function ManageEvent() {
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [show, setShow] = useState(false);
  const [perpage, setPerpage] = useState(20);
  const [dateRange, setDateRange] = useState([null, null]);
  const [idvalue, setIdvalue] = useState("");
  const [senddateRange, setsenDateRange] = useState({ from: null, to: null });
  const eventlist = useSelector((state) => state.Administrator.eventlist);

  const [state, setState] = useState({});

  const ref = useRef();
  const { events } = useDraggable(ref);

  useEffect(() => {
    if (eventlist) {
      setState({ ...state, ...eventlist })
    }
  }, [eventlist])


  console.log("state", state)

  useEffect(() => {
    dispatch(
      eventlistDataAction({
        perpage: Number(perpage),
        pageno: pageno,
        serachkeyval: serachkeyval,
        senddateRange: senddateRange,
      })
    );
  }, [pageno, perpage, serachkeyval, senddateRange]);


  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };
  const navigate = useNavigate();

  const deleteevent = () => {
    dispatch(
      deleteeventAction({
        id: idvalue,
      })
    ).then(function () {
      navigate("/manage-event");
      dispatch(
        eventlistDataAction({
          perpage: perpage,
          pageno: pageno,
          serachkeyval: serachkeyval,
          senddateRange: senddateRange,
        })
      );
      setShow(false);
    });
  };
  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };

  const handleDateRangeChange = (value) => {
    console.log(value, "valuevalue");
    if (value != null) {
      setsenDateRange({
        from: moment(value[0]).format("YYYY-MM-DD"),
        to: moment(value[1]).format("YYYY-MM-DD"),
      });
    } else {
      setsenDateRange({
        from: null,
        to: null,
      });
    }

    setDateRange(value);
  };

  const DraggableItem = ({ item, index, moveItem }) => {
    const ref = React.useRef(null);
    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = draggedItem.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        moveItem(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
    });

    const [, drag] = useDrag({
      type: "ITEM",
      item: { index },
      end: (draggedItem) => {
        // Finalize the move when dragging ends
        moveItem(draggedItem.index, index, true);
      },
    });

    drag(drop(ref));

    return (
      <>
        <tr ref={ref} key={index}>
          <td style={{ cursor: "move" }}>
            <svg
              width="9"
              height="15"
              viewBox="0 0 9 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="#171717" />
              <circle cx="1.5" cy="7.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="7.5" r="1.5" fill="#171717" />
              <circle cx="1.5" cy="13.5" r="1.5" fill="#171717" />
              <circle cx="7.5" cy="13.5" r="1.5" fill="#171717" />
            </svg>
            <span className="ms-4">
              {index + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}
            </span>
          </td>
          <td>
            {moment(item?.date).format("MM/DD/YYYY")}
          </td>
          <td>
            {item?.start_time + "-" + item?.end_time}
          </td>
          <td>{item?.address}</td>
          <td>{item?.title}</td>
          <td className="user-img">
            <img src={item?.image} />
          </td>
          <td className="btn-set">
            <div
              className="account-btn common-colr-btn"
              onClick={() => {
                navigate(
                  "/view-event/" + Encryptedid(item?._id)
                );
              }}
            >
              View
            </div>{" "}
            <Button
              className="account-btn common-colr-btn"
              onClick={() => {
                setIdvalue(item?._id);
                setShow(true);
              }}
            >
              Delete
            </Button>{" "}
          </td>
        </tr>
      </>
    );
  };

  const moveItem = (fromIndex, toIndex, finalize = false) => {
    const updatedItems = [...state?.eventget];
    const [movedItem] = updatedItems.splice(toIndex, 1);
    updatedItems.splice(fromIndex, 0, movedItem);
    setState({ ...state, eventget: updatedItems })
    if (finalize) {
      const allEventIds = updatedItems?.map((res, i) => {
        let payload = {
          _id: res?._id,
          new_s_no: i
        }
        return payload
      });

      console.log("allEvent", allEventIds)
      dispatch(
        arrangeEventListOrderAction({
          event_data: allEventIds,
          perpage: Number(perpage),
          pageno: pageno,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(
            eventlistDataAction({
              perpage: Number(perpage),
              pageno: pageno,
              serachkeyval: serachkeyval,
              senddateRange: senddateRange,
            })
          );
        }
      });
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Events</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <DateRangePicker
                  value={dateRange}
                  onChange={handleDateRangeChange}
                />
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn me-2">
                  <Link to="/create-event">Create Event</Link>
                </div>
                <div className="cmn-btn">

                  <DownloadComponent action={gelistsexporteventAction} serachkeyval={serachkeyval} />

                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (state?.totalpagination > 10
                      ? rangeEnd
                      : state?.totalpagination) +
                    " of " +
                    state?.totalpagination?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive"  {...events}
          ref={ref}>
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Time</th>
                <th>Address</th>
                <th>Title</th>
                <th>Image</th>


                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <DndProvider backend={HTML5Backend}>
                {state?.eventget?.length > 0 ? (
                  state?.eventget?.map((item, index) => {
                    return (
                      <DraggableItem
                        key={item?._id}
                        item={item}
                        index={index}
                        moveItem={moveItem}
                      />
                    );
                  })
                ) : (
                  <div className="data-not-found">
                    <p>No Data Found</p>
                  </div>
                )}
              </DndProvider>
            </tbody>

          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={state?.totalpagination}
        paginationevent={paginationevent}
      />
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"Faq"}
        Id={idvalue}
        deleteuser={deleteevent}
      />
    </Layout>
  );
}
