
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";
import { toast } from "react-toastify";



export const AdminlistAction = createAsyncThunk('Adminlist', async (obj) => {

     const data = await Api.post("/adminauth/adminlist", obj);

     if (data.data.status == 201) {

          return data.data.adminlist
     }
})


export const ProfiledetailsAction = createAsyncThunk('Profiledetails', async (obj) => {

     const data = await Api.get(`/adminauth/profiledetails?id=${obj}`);

     if (data.data.status == 201) {

          return data.data.admindetails
     }

})


export const donationlistAction = createAsyncThunk('donationlist', async (obj) => {

     const { data } = await Api.post("/adminauth/donationlist", obj);

     if (data.status == 201) {

          return data.donationlistdata
     }

})


export const volunteerlistAction = createAsyncThunk('volunteerlist', async (obj) => {

     const { data } = await Api.post("/adminauth/volunteerlist", obj);

     if (data.status == 201) {

          return data.volunteerlistdata
     }

})









export const UserlistAction = createAsyncThunk('Userlist', async (obj) => {

     const data = await Api.post("/adminauth/userlist", obj);

     if (data.data.status == 201) {

          return data.data.adminlist
     }

})



export const deleteAdminuserAction = createAsyncThunk('deleteAdminuser', async (obj) => {

     const data = await Api.post(`/adminauth/deleteAdminuser`, obj);

     if (data.data.status == 201) {

          return data.data.admindetails
     }

})



export const memberprofiledetailsAction = createAsyncThunk('memberprofiledetails', async (obj) => {

     const data = await Api.post(`/adminauth/member-profile-details`, obj);

     if (data.data.status == 201) {
          return data.data.memberdetails
     }

})


export const updatememberprofileAction = createAsyncThunk('updatememberprofile', async (obj) => {

     const data = await Api.post(`/adminauth/updatememberprofile`, obj);

     if (data.data.status == 201) {
          toast.success("Information successfully updated")
          return data.data.userdetails
     }

})



export const updateprofilepicAction = createAsyncThunk('updateprofilepic', async (obj) => {

     const data = await Api.post(`/adminauth/updateprofilepic`, obj);

     if (data.data.status == 201) {

          return data.data.file
     }

})

export const eventimageAction = createAsyncThunk('eventimage', async (obj) => {

     const { data } = await Api.post(`/adminauth/eventimage`, obj);
     return data.imagename.eventimage[0]


})






export const deleteeventAction = createAsyncThunk('deleteevent', async (obj) => {

     const data = await Api.post(`/adminauth/deleteevent`, obj);

     if (data.data.status == 201) {
          return data.data.saveinformation
     } else {
          toast.error(data.data.message)
     }

})




export const createEventAction = createAsyncThunk('createEvent', async (obj) => {

     const data = await Api.post(`/adminauth/createEvent`, obj);

     if (data.data.status == 201) {
          return data.data.saveinformation
     } else {
          toast.error(data.data.message)
     }

})

export const eventlistDataAction = createAsyncThunk('eventlistData', async (obj) => {

     const { data } = await Api.post(`/adminauth/eventlistData`, obj);


     if (data.status == 201) {
          return data.eventlistvalue
     }

})

export const arrangeEventListOrderAction = createAsyncThunk('arrange/Event/Order-List', async (reqBody) => {
     const { data } = await Api.post(`/adminauth/rearrange-events`, reqBody);
     return data;
}
);

export const arrangeFAQListOrderAction = createAsyncThunk('arrange/Faq/Order-List', async (reqBody) => {
     const { data } = await Api.post(`/adminauth/rearrange-faqs`, reqBody);
     return data;
}
);

export const arrangeEmployeeListOrderAction = createAsyncThunk('arrange/Employee/Order-List', async (reqBody) => {
     const { data } = await Api.post(`/adminauth/rearrange-employees`, reqBody);
     console.log("action", data)
     return data;
}
);

export const eventdetailsDataAction = createAsyncThunk('eventdetails', async (obj) => {

     const { data } = await Api.post(`/adminauth/eventdetails`, obj);


     if (data.status == 201) {
          return data.eventdetail
     }

})





export const accountsuspendedAction = createAsyncThunk('accountsuspended', async (obj) => {

     const data = await Api.post(`/adminauth/accountsuspended`, obj);

     if (data.data.status == 201) {
          return data.data.userlist
     }

})

export const clubdetailsAction = createAsyncThunk('clubdetails', async (obj) => {

     const data = await Api.post(`/adminauth/clubdetails`, obj);

     if (data.data.status == 201) {
          return data.data.getclublists
     }

})

export const deleteuserAction = createAsyncThunk('deleteuser', async (obj) => {

     const data = await Api.post(`/adminauth/deleteuser`, obj);

     if (data.data.status == 201) {
          return data.data.getclublists
     }

})

export const dashBoardAdminAction = createAsyncThunk('dashBoardAdmin', async (obj) => {

     const data = await Api.get(`/adminauth/dashBoardAdmin`);

     if (data.data.status == 200) {
          return data.data.dashboard
     }

})


export const usersuspendedAction = createAsyncThunk('usersuspended', async (obj) => {

     const data = await Api.post(`/adminauth/usersuspended`, obj);

     if (data.data.status == 201) {


          return data.data.userlist
     }

})


export const categoryandsubcategorylistAction = createAsyncThunk('categoryandsubcategorylist', async (obj) => {

     const data = await Api.get(`/adminauth/categoryandsubcategorylist`);

     if (data.data.status == 200) {


          return data.data.Listcategorysub
     }

})






//blog action section 

export const addandupdateblogAction = createAsyncThunk('addandupdateblog', async (obj) => {
     const data = await Api.post(`/adminauth/addandupdateblog`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const deleteblogAction = createAsyncThunk('deleteblog', async (obj) => {
     const data = await Api.post(`/adminauth/deleteblog`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const getbloglistAction = createAsyncThunk('getbloglist', async (obj) => {
     const data = await Api.post(`/adminauth/getbloglist`, obj);
     if (data.data.status == 201) {
          return data.data.getbloglists
     }
})
export const blogdetailsAction = createAsyncThunk('blogdetails', async (obj) => {
     const data = await Api.post(`/adminauth/blogdetails`, obj);
     if (data.data.status == 201) {
          return data.data.getblogdetails
     }
})

export const blogimageAction = createAsyncThunk('blogimage', async (obj) => {
     const { data } = await Api.post(`/adminauth/blogimage`, obj);

     if (data.status == 201) {
          console.log(data)
          return data.imagename.blogimage[0]
     }
})





//end blog action section 








//faq action section 

export const addandupdatefaqAction = createAsyncThunk('addandupdatefaq', async (obj) => {
     const data = await Api.post(`/adminauth/addandupdatefaq`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const deletefaqAction = createAsyncThunk('deletefaq', async (obj) => {
     const data = await Api.post(`/adminauth/deletefaq`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const getfaqlistAction = createAsyncThunk('getfaqlist', async (obj) => {
     const data = await Api.post(`/adminauth/getfaqlist`, obj);
     if (data.data.status == 201) {
          return data.data.getfaqlists
     }
})
export const faqdetailsAction = createAsyncThunk('faqdetails', async (obj) => {
     const data = await Api.post(`/adminauth/faqdetails`, obj);
     if (data.data.status == 201) {
          return data.data.getfaqdetails
     }
})







//end faq action section 




//faq action section 

export const addandupdatejobAction = createAsyncThunk('addandupdatejob', async (obj) => {
     const data = await Api.post(`/adminauth/addandupdatejob`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const deletejobAction = createAsyncThunk('deletejob', async (obj) => {
     const data = await Api.post(`/adminauth/deletejob`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})
export const getjoblistAction = createAsyncThunk('getjoblist', async (obj) => {
     const data = await Api.post(`/adminauth/getjoblist`, obj);
     if (data.data.status == 201) {
          return data.data.getjoblists
     }
})

export const jobdetailsAction = createAsyncThunk('jobdetails', async (obj) => {
     const data = await Api.post(`/adminauth/jobdetails`, obj);
     if (data.data.status == 201) {
          return data.data.getfaqdetails
     }
})

export const getvolunteerlistexportdataAction = createAsyncThunk('getvolunteerlistexportdata', async (obj) => {
     const { data } = await Api.post(`/adminauth/getvolunteerlistexportdata`, obj);
     if (data.status == 201) {
          return data.userlist
     }
})








//end faq action section 




//Link


export const createlinkAction = createAsyncThunk(' create-link', async (obj) => {
     const data = await Api.post(`/adminauth/create-link`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})

export const linklistdataAction = createAsyncThunk('link-list-data', async (obj) => {
     const { data } = await Api.post(`/adminauth/link-list-data`, obj);
     if (data.status == 201) {
          return data.linklistvalue
     }
})
export const deletelinkinfoAction = createAsyncThunk('delete-link-info', async (obj) => {
     const { data } = await Api.post(`/adminauth/delete-link-info`, obj);
     if (data.status == 201) {
          return data.linkdeleted
     }
})

export const linkdetailsAction = createAsyncThunk('link-details', async (obj) => {
     const { data } = await Api.post(`/adminauth/link-details`, obj);
     if (data.status == 201) {
          return data.getlinkdetails
     }
})

//end link



//Link


export const addandupdateemployeeAction = createAsyncThunk('addandupdateemployee', async (obj) => {
     const data = await Api.post(`/adminauth/addandupdateemployee`, obj);
     if (data.data.status == 201) {
          return data.data.saveinformation
     }
})

export const getemployeelistAction = createAsyncThunk('getemployeelist', async (obj) => {
     const { data } = await Api.post(`/adminauth/getemployeelist`, obj);
     if (data.status == 201) {
          return data.getemployeelists
     }
})
export const employeedetailsAction = createAsyncThunk('employeedetails', async (obj) => {
     const { data } = await Api.post(`/adminauth/employeedetails`, obj);
     if (data.status == 201) {
          return data.getemployeedetails
     }
})

export const deleteemployeeAction = createAsyncThunk('deleteemployee', async (obj) => {
     const { data } = await Api.post(`/adminauth/deleteemployee`, obj);
     if (data.status == 201) {
          return data.getlinkdetails
     }
})

export const gelistsexportAction = createAsyncThunk('gelistsexport', async (obj) => {
     const { data } = await Api.post(`/adminauth/gelistsexport`, obj);
     if (data.status == 201) {
          return data.checkinlist
     }
})
export const gelistsexporteventAction = createAsyncThunk('gelistsexportevent', async (obj) => {
     const { data } = await Api.post(`/adminauth/gelistsexportevent`, obj);
     if (data.status == 201) {
          return data.checkinlist
     }
})

export const gelistsexportjobAction = createAsyncThunk('gelistsexportjob', async (obj) => {
     const { data } = await Api.post(`/adminauth/gelistsexportjob`, obj);
     if (data.status == 201) {
          return data.checkinlist
     }
})







export const employeeimageAction = createAsyncThunk('employeeimage', async (obj) => {
     const { data } = await Api.post(`/adminauth/employeeimage`, obj);

     if (data.status == 201) {
          console.log(data)
          return data.imagename.employeeimage[0]
     }
})



//end link





//upload document

export const uploadDocumentAction = createAsyncThunk('uploadDocument', async (obj) => {
     const data = await Api.post(`/adminauth/uploadDocument`, obj);
     if (data.data.status == 201) {
          return data.data.userdetails
     }
})


export const uploadDocumentclubAction = createAsyncThunk('uploadDocumentclub', async (obj) => {
     const data = await Api.post(`/adminauth/uploadDocumentclub`, obj);
     if (data.data.status == 201) {
          return data.data.getclubdetails
     }
})




//push






export const addpushnotificationAction = createAsyncThunk('addpushnotification', async (obj) => {
     const data = await Api.post(`/adminauth/addpushnotification`, obj);

     if (data.data.status == 200) {

          return data.data.notificatoinadd
     }
})
export const listnotificationAction = createAsyncThunk('listnotification', async (obj) => {
     const data = await Api.post(`/adminauth/listnotification`, obj);
     if (data.data.status == 200) {
          return data.data.notificationlist
     }
})
export const pushnotificationallowmemberlistAction = createAsyncThunk('pushnotificationallowmemberlist', async (obj) => {
     const data = await Api.post(`/adminauth/pushnotificationallowmemberlist`, obj);
     if (data.data.status == 200) {
          return data.data.userlist
     }
})


//mailing


export const addmailingAction = createAsyncThunk('addmailing', async (obj) => {
     const data = await Api.post(`/adminauth/addmailing`, obj);

     if (data.data.status == 200) {

          return data.data.mailingadd
     }
})

export const listmailingAction = createAsyncThunk('listmailing', async (obj) => {
     const data = await Api.post(`/adminauth/listmailing`, obj);
     if (data.data.status == 200) {
          return data.data.mailinglist
     }
})
export const mailallowmemberlistAction = createAsyncThunk('mailallowmemberlist', async (obj) => {
     const data = await Api.post(`/adminauth/mailallowmemberlist`, obj);
     if (data.data.status == 200) {
          return data.data.userlist
     }
})



export const userActivityaddAction = createAsyncThunk('userActivityadd', async (obj) => {
     const { data } = await Api.post(`/adminauth/userActivityadd`, obj);


})



export const imagesaveAction = createAsyncThunk('imagesave', async (obj) => {
     const { data } = await Api.post(`/adminauth/imagesave`, obj);
     return data


})
export const checkincheckoutsaveAction = createAsyncThunk('checkincheckoutsave', async (obj) => {
     const { data } = await Api.post(`/adminauth/checkincheckoutsave`, obj);
     return data


})
export const volunteerlogdetailsAction = createAsyncThunk('volunteerlogdetails', async (obj) => {
     const { data } = await Api.post(`/adminauth/volunteerlogdetails`, obj);
     return data


})

export const deleteVolunterAction = createAsyncThunk('voluter/delete', async (reqBody) => {
     const response = await Api.delete(`/adminauth/volunteer/${reqBody}`);
     return response.data
})

export const deleteRegInterestAction = createAsyncThunk('reginterest/delete', async (reqBody) => {
     const response = await Api.put(`/adminauth/deleteUser/interest/${reqBody}`);
     return response.data
})


export const RegInterestListAction = createAsyncThunk('regInterest/List', async (obj) => {

     const data = await Api.post("/adminauth/user-interest-list", obj);

     if (data.data.status == 201) {

          return data.data.adminlist
     }

})



