import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function ManageServices() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Chat</h2>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12} className="d-flex align-items-center">
              <div className="view-chat mt-5 mb-5">
                <Link to="#" onClick={handleShow}>
                  View Chat
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="no-conversations">
              <p>No Conversations Yet.</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none" closeButton></Modal.Header>
        <Row>
          <Col lg={12}>
            <div className="chat-box-select d-flex justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label>First User</Form.Label>

                <Form.Select aria-label="Default select example">
                  <option>Select User</option>
                  <option value="1">All Vendors</option>
                  <option value="2">All Property Managers</option>
                  <option value="3">All Users</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
          <Col lg={12}>
            <div className="chat-box-select d-flex justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label>Second User</Form.Label>

                <Form.Select aria-label="Default select example">
                  <option>Select User</option>
                  <option value="1">All Vendors</option>
                  <option value="2">All Property Managers</option>
                  <option value="3">All Users</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Modal.Footer className="border-none justify-content-center mb-3">
          <Link className="modal-close" onClick={handleClose}>
            Close
          </Link>
          <Link to="/ManageChat" className="modal-open-view" onClick={handleClose}> 
            View Chat
          </Link>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
