import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";
import { Encryptedid } from "../../Util/BcruptEncyptid";
import { ToastContainer, toast } from "react-toastify";

export const LoginAction = createAsyncThunk("Adminlogin", async (obj) => {
  try {
    const data = await Api.post("/adminauth/adminlogin", obj);
    console.log(data);

    if (data?.data?.status == 200) {
      if (data.data.userdetails.role == 0) {
        const bcrypt = Encryptedid(data.data.userdetails._id);
        window.location.href = "/login-otp/" + bcrypt;
      } else {
        window.location.href = "/dashboard";
        localStorage.setItem("userid", data.data.userdetails._id);
        localStorage.setItem("accessToken", data.data.token);
      }
    } else {
      toast.error(data.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const checkotpAction = createAsyncThunk("checkotp", async (obj) => {
  const data = await Api.post("/adminauth/checkotp", obj);

  if (data.data.status == 200) {
    if (data.data.userdetails.resetpasswordotpverify == 1) {
      const bcrypt = Encryptedid(data.data.userdetails._id);
      window.location.href = "/Resetpassword/" + bcrypt;
    } else {
      window.location.href = "/dashboard";
      localStorage.setItem("userid", data.data.userdetails._id);
      localStorage.setItem("accessToken", data.data.token);
    }
  } else {
    toast.error(data.data.message);
  }
});

export const AddadmincreateAction = createAsyncThunk(
  "Addadmincreate",
  async (obj) => {
    const data = await Api.post("/adminauth/addadmincreate", obj);
    // console.log(data.data,"data");
  }
);

export const forgotpasswordAction = createAsyncThunk(
  "forgotpassword",
  async (obj) => {
    const data = await Api.post("/adminauth/forgotpassword", obj);

    if (data.data.status == 200) {
      console.log(data.data);

      const bcrypt = Encryptedid(data.data.userdetails._id);
      window.location.href = "/ResetOtp/" + bcrypt;

      toast.error(data.data.message);
    } else {
      toast.error(data.data.message);
    }
  }
);

export const adminpasswordresetAction = createAsyncThunk(
  "passwordreset",
  async (obj) => {
    const data = await Api.post("/adminauth/passwordreset", obj);

    if (data.data.status == 200) {
      toast.success(
        "Your password has been reset successfully. You can now log in with your new credentials"
      );

      window.location.href = "/";
    } else {
      toast.error(data.data.message);
    }
  }
);

export const passwordresetuserAction = createAsyncThunk(
  "passwordresetuser",
  async (obj) => {
    const data = await Api.post("/auth/passwordresetuser", obj);

    if (data.data.status == 200) {
      toast.success(
        "Your password has been reset successfully. You can now log in with your new credentials"
      );
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast.error(data.data.message);
    }
  }
);

export const resetpasswordlinkAction = createAsyncThunk(
  "resetpasswordlink",
  async (obj) => {
    const data = await Api.post("/adminauth/resetpasswordlink", obj);

    if (data.data.status == 200) {
      toast.success("Reset password link sent.");
    } else {
      toast.error(data.data.message);
    }
  }
);

export const resettemppasswordAction = createAsyncThunk(
  "resettemppassword",
  async (obj) => {
    const data = await Api.post(`/adminauth/resettemppassword`, obj);

    if (data.data.status == 201) {
      toast.success(
        "The temporary password has been sent to the user's email."
      );
    } else {
      toast.error(data.data.message);
    }
  }
);

export const changepasswordAction = createAsyncThunk(
  "changepassword",
  async (obj) => {
    const data = await Api.post(`/adminauth/changepassword`, obj);

    if (data.data.status == 201) {
      toast.success("Your password change successfully");
    } else {
      toast.error(data.data.message);
    }
  }
);

export const interestListAction = createAsyncThunk(
  "interestList",
  async (obj) => {
    const data = await Api.get(`/adminauth/interestList`, obj);

    if (data.data.status_code == 200) {
      return data.data.data;
    }
  }
);
