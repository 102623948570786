import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function DeleteComponent({
  show, handleClose,
  type,
  deleteuser
}) {


  return (
    <Modal show={show} onHide={handleClose} className="delete-popup">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {/* <img src={require("../Assets/Images/delete.svg").default} /> */}
        <p> Are you sure you want to delete {(type === "VOLUNTER" || type === "REGINTEREST") ? "" : "this Account"}?</p>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={deleteuser}>
          Yes
        </Button>
        <Button variant="primary" onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
