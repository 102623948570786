import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useDispatch, useSelector } from "react-redux";
import { deleteuserAction, memberprofiledetailsAction, updatememberprofileAction, updateprofilepicAction, userActivityaddAction, usersuspendedAction } from "../Redux/Action/AdminstratorAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { interestListAction, resettemppasswordAction } from "../Redux/Action/Authaction";
import DeleteComponent from "../Components/DeleteComponent";
import Usersuspended from "../Components/Usersuspended";
export default function UserProfile() {
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);
  const [showsuspended, setShowsuspended] = useState(false);
  const [filepath, setFilefilepath] = useState("");
  const [interestlist, setInterestlist] = useState([]);
  function handleChange(e) {
    console.log(e.target.files);
  let formdata=new FormData();
  formdata.append('profilepic',e.target.files[0])
  dispatch(updateprofilepicAction(formdata)).then(function(payload){
    
    setFilefilepath(process.env.REACT_APP_IMAGE_URL+payload.payload)

  })

    
    setFile(URL.createObjectURL(e.target.files[0]));
  }



  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      memberprofiledetailsAction({
        _id: idvalue,
      })
    ).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {

          if(key=="profileImage"){
            setFilefilepath(value)
          }else{

      
          if(key=="interests"){
            formik.setFieldValue(key, value?._id, { strict: false });
          }else{
            formik.setFieldValue(key, value, { strict: false });
          }
        }
          
          
        }
      }
    });
    dispatch(interestListAction()).then(function(data){
      setInterestlist(data.payload)
    })
  }, [idvalue]);

  const memberdetails = useSelector(
    (state) => state.Administrator.memberdetails
  );


  const formik = useFormik({
    initialValues: {
      firstName: memberdetails?.firstName || "",
      lastName: memberdetails?.lastName || "",
      email: memberdetails?.email || "",
      phoneNumber: memberdetails?.phone || "",
      address: memberdetails?.address || "",
      interests: memberdetails?.interests || "",
     
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Last Name is required"),
      firstName: Yup.string().required("First Name is required"),
      interests: Yup.string().required("Interest is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
        phoneNumber: Yup.number().required("Phone is required"),
      address: Yup.string().required("Address is required"),
     
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = idvalue;
      values.profileImage = filepath;


      
      dispatch(updatememberprofileAction(values)).then(function () {
       // navigate("/Members");
  
      dispatch(
        memberprofiledetailsAction({
          _id: idvalue,
        })
      ).then(function (data) {
        if (data.payload) {
          var Details = data.payload;
          for (const [key, value] of Object.entries(Details)) {
  
            if(key=="profileImage"){
              setFilefilepath(value)
            }else{
  
        
            if(key=="interests"){
              formik.setFieldValue(key, value?._id, { strict: false });
            }else{
              formik.setFieldValue(key, value, { strict: false });
            }
          }
            
            
          }
        }
      });

      dispatch(userActivityaddAction({
        userId:idvalue,
        title:"Profile changes",
      }))

    });
    },
  });
const navigate=useNavigate();

  const handleClose = () => setShow(false);
  const deleteuser = () => {
    dispatch(
      deleteuserAction({
        id: idvalue,
      })
    ).then(function () {
      navigate("/UserManagement");
      setShow(false);
    });
  };

  const handleClosesus = () => setShowsuspended(false);

  const suspend = () => {
    setShowsuspended(false);
    dispatch(
      usersuspendedAction({
        id: idvalue,
        accountstatus: memberdetails?.accountstatus == 1 ? 0 : 1,
      })
    );

    dispatch(userActivityaddAction({
      userId:idvalue,
      title: memberdetails?.accountstatus == 1 ? "profile suspended" :"profile Active",
    }))
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn tab">
                <Link to={"/user-profile/"+id} className="active">
                  Overview
                </Link>
                <Link to={"/donations/"+id} className="ms-2">
                  Donations
                </Link>
                <Link to={"/volunteer-logs/"+id} className="ms-2">
                  Volunteer Log
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg={12}>
            <Row>
              <Container fluid>
                <Row className="justify-content-center"></Row>
              </Container>
             
              <Col lg={8}>
                <div className="mb-4 user-profile-main customer-form-new">
                  <div className="customer-form-inner">
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Image</Form.Label>
                          <div className="image-upload">
                            <input
                              type="file"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <p>Upload Image</p>
                            <img className="preview-img" src={filepath !=""?filepath:file} />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" name="firstName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                            />
                            {formik.errors.firstName && (
                              <div className="errors">
                                {formik.submitCount > 0 && formik.errors.firstName}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" placeholder="Type Here"  name="lastName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                            />
                            {formik.errors.lastName && (
                              <div className="errors">
                                {formik.submitCount > 0 && formik.errors.lastName}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" placeholder="Type Here"  name="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.errors.email && (
                              <div className="errors">
                                {formik.submitCount > 0 && formik.errors.email}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" name="phoneNumber"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.phoneNumber}
                            />
                            {formik.errors.phoneNumber && (
                              <div className="errors">
                                {formik.submitCount > 0 && formik.errors.phoneNumber}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type Here"
                            className="mb-2"
                            name="address"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.address}
                            />
                            {formik.errors.address && (
                              <div className="errors">
                                {formik.submitCount > 0 && formik.errors.address}
                              </div>
                            )}
                        
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Interested In</Form.Label>
                         

                            <Form.Select
                              aria-label="Default select example"
                              name="interests"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.interests}
                            >
                              <option value="">Please select Interest</option>
                              {
                                interestlist?.map(function(object){
                                  return(
                                    <option value={object?._id}>{object?.interestName}</option>
                                  )
                                })
                              }
                             
                             
                            </Form.Select>

                            {formik.submitCount > 0 && formik.errors.interests && (
                              <div className="errors">{formik.errors.interests}</div>
                            )}
                          </Form.Group>
                   
                      </Col>
                      <Col lg={12} className="d-flex justify-content-center">
                        <div className="cmn-btn">
                          <button type="submit">Save</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            
              {/* <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={6}>
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={require("../Assets/Images/user.png")}
                        />
                        <Figure.Caption>
                          <h2>Business Name</h2>

                          <p>
                            <span>Email - </span>
                            22 Shelton Street, London SW6
                          </p>
                          <p className="mb-3">
                            <span>Phone - </span> 
                            United Kingdom
                          </p>
                          <p>email@domain.com</p>
                          <p>+447380 605060</p>
                          <Link to="#" className="text-black">
                            www.domain.com
                          </Link>
                        </Figure.Caption>
                      </Figure>
                    </Col>
                    <Col lg={6}>
                      <div class="form-check user-profile">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Getting my church involved
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col> */}
              <Col lg={4}>
                <Row>
                  {/* <Col lg={12} className="mb-3">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Delete Account</h2>
                            <Figure.Caption className="m-0 mt-2">
                              <p>
                                Once you delete your account, you can not
                                retrieve the account. Please be certain.
                              </p>
                            </Figure.Caption>
                            <div class="dlt-ac-btn mt-4">
                              <a href="/Support">Delete Account</a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} className="mb-2">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Suspend account</h2>
                            <Figure.Caption className="m-0 mt-2">
                              <p>
                                You will not be able to receive messages,
                                notifications for up to 24hours.
                              </p>
                            </Figure.Caption>
                            <div class="dlt-ac-btn mt-4">
                              <a href="/Support">Suspend Account</a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    <div className="product-overview-box">
                      <h2>ADMIN ACTIONS</h2>
                      <button type="button" className="edit-product"   onClick={() => {
                    dispatch(
                      resettemppasswordAction({
                        id: memberdetails?._id,
                      })
                    );

                    dispatch(userActivityaddAction({
                        userId:idvalue,
                        title: "Sent reset password",
                      }))
                  }}>
                        RESET PASSWORD
                      </button>
                      <button
                  type="button"
                  className="edit-product"
                  onClick={setShowsuspended}
                >
                  {memberdetails?.accountstatus == 1
                    ? "SUSPEND ACCOUNT"
                    : "ACTIVATE ACCOUNT"}
                </button>
                      <button type="button" className="edit-product"   onClick={() => {
                    setShow(true);
                  }}>
                        DELETE / CLOSE ACCOUNT
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-2 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <ul>
                          {
                            memberdetails?.activities?.map(function(logsobject,i){
                              return(
                                <li>
                              <h6>{logsobject?.adminId?.name} {logsobject?.title}</h6>
                                 <p>  {moment(logsobject?.date).format("MM/DD/YYYY")} {moment(logsobject?.date).format("HH:mm")} </p>
                           
                            </li>
                              )
                            })
                          }
                         
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        </form>
      </Container>
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"User"}
        Id={idvalue}
        deleteuser={deleteuser}
      />

      <Usersuspended
        show={showsuspended}
        handleClose={handleClosesus}
        type={"customer"}
        Id={idvalue}
        suspenduser={suspend}
      />

    </Layout>
  );
}
