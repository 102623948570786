import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { deleteVolunterAction, getvolunteerlistexportdataAction, volunteerlistAction } from "../Redux/Action/AdminstratorAction";
import * as XLSX from 'xlsx/xlsx.mjs';
// import { DateRangePicker } from "rsuite";
// import "rsuite/dist/rsuite.css";
import moment from "moment";
import Pagination from "../Components/Pagination";
import DownloadComponent from "../Components/DownloadComponent";
import DeleteComponent from "../Components/DeleteComponent";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
export default function VolunteerManagement() {


  const dispatch = useDispatch();
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [pageno, setPageno] = useState(1);
  const [perpage, setPerpage] = useState(20);
  const [isShowModal, setIsShowModal] = useState(false);
  const [idVal, setIdVal] = useState("");
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  useEffect(() => {
    dispatch(
      volunteerlistAction({
        perpage: Number(perpage),
        pageno: pageno,
        serachkeyval: serachkeyval,
      })
      // setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1))
    );

  }, [pageno, perpage, serachkeyval]);


  const volunteerlistlogs = useSelector((state) => state.Administrator.volunteerlistlogs);


  const paginationevent = (pag) => {
    setPageno(pag);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const handleShow = (id) => {
    setIsShowModal(true)
    setIdVal(id)
  }

  const handleClose = () => {
    setIsShowModal(false);
    setIdVal("");
  };

  const deletejob = () => {
    dispatch(deleteVolunterAction(idVal)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message)
        dispatch(
          volunteerlistAction({
            perpage: Number(perpage),
            pageno: pageno,
            serachkeyval: serachkeyval,
          })
        );
        setIsShowModal(false);
      } else {
        toast.error(res?.payload?.message)
      }

    });
  };



  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Volunteer Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={3}>
                <DateRangePicker placeholder="Select Date Range" />
              </Col> */}
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <DownloadComponent action={getvolunteerlistexportdataAction} serachkeyval={serachkeyval} />

                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        {/* <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="/TemporaryBanned">Temporary Banned</Link>
              </li>
              <li>
                <Link to="/UserActive">Active</Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (volunteerlistlogs?.Totalcheckin > 10 ? rangeEnd : volunteerlistlogs?.Totalcheckin) +
                    " of " +
                    volunteerlistlogs?.Totalcheckin?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}>
                  <option>20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Organization</th>
                <th>PhoneNumber</th>
                <th>Purpose of Visit</th>
                <th>Device Location </th>
                <th>Address</th>
                <th>Interested In</th>
                <th>Date</th>
                <th>Login Details</th>
                <th>Logout Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                volunteerlistlogs?.checkinlist?.map(function (object, i) {
                  return (
                    <tr
                      onClick={() => {
                        navigate('/view-volunteer-log/' + object?._id)
                      }}
                    >
                      <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                      <td>{object?.user_details?.firstName + " " + object?.user_details?.lastName}</td>
                      <td>{object?.organizationname}</td>
                      <td>{object?.phonenumber == undefined ? "---" : object?.phonenumber}</td>

                      <td>{object?.visit}</td>
                      <td>  <a href={"https://maps.google.com?q=" + object.lat + "," + object.long} target="_blank"> {object.lat + "/" + object.long}</a></td>
                      <td>{object?.address == undefined ? "---" : object?.address}</td>
                      <td>{object?.user_details?.interests[0]?.interestName}</td>
                      <td>{moment(object?.createdAt).format("MM/DD/YYYY")}</td>
                      <td>{moment(object?.intime).format("HH:mm")}</td>

                      <td>{object?.outtime == undefined ? "" : moment(object?.outtime).format("HH:mm")}</td>
                      <td>
                        <Button
                          className="account-btn common-colr-btn"
                          type="button" onClick={(event) => {
                            event.stopPropagation();
                            handleShow(object?._id);
                          }}
                        >
                          Delete
                        </Button>{" "}
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </Table>
        </div>
      </Container>


      <DeleteComponent
        show={isShowModal}
        handleClose={handleClose}
        type={"VOLUNTER"}
        Id={idVal}
        deleteuser={deletejob}
      />

      <Pagination
        totalpagination={volunteerlistlogs?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
