import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deletelinkinfoAction,
  linklistdataAction,
} from "../Redux/Action/AdminstratorAction";
import { Encryptedid } from "../Util/BcruptEncyptid";
import moment from "moment";
import Pagination from "../Components/Pagination";
import DeleteComponent from "../Components/DeleteComponent";
export default function AllLinks() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [idvalue, setIdvalue] = useState("");
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(20);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      linklistdataAction({
        perpage: Number(perpage),
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const linklist = useSelector((state) => state.Administrator.linklist);

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIdvalue("");
  };
  const deletelink = () => {
    dispatch(
      deletelinkinfoAction({
        id: idvalue,
      })
    ).then(function() {
      navigate("/all-links");
      dispatch(
        linklistdataAction({
          perpage: perpage,
          pageno: pageno,
          tab: tab,
          serachkeyval: serachkeyval,
        })
      );
      setShow(false);
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Links</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/create-link">Create </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (linklist?.Totallink > 10
                      ? rangeEnd
                      : linklist?.Totallink) +
                    " of " +
                    linklist?.Totallink?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>CREATED</th>
                <th>Title</th>
                <th>URL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {linklist?.alllist?.map(function(linkobject, i) {
                return (
                  <tr>
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td>
                      {moment(linkobject?.createdAt).format("MM/DD/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(linkobject?.createdAt).format("HH:mm")}
                      </span>
                    </td>

                    <td
                      onClick={() => {
                        navigate("/edit-link/" + Encryptedid(linkobject?._id));
                      }}
                    >
                      {linkobject?.title}
                    </td>
                    <td>{linkobject?.url}</td>
                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setIdvalue(linkobject?._id);
                          setShow(true);
                        }}
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={linklist?.totalpagination}
        paginationevent={paginationevent}
      />
      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"Link"}
        Id={idvalue}
        deleteuser={deletelink}
      />
    </Layout>
  );
}
