import { createSlice } from "@reduxjs/toolkit";
import { AdminlistAction, usersuspendedAction, ProfiledetailsAction, UserlistAction, accountsuspendedAction, addandupdateblogAction, blogdetailsAction, categoryandsubcategorylistAction, clubdetailsAction, dashBoardAdminAction, getbloglistAction, listnotificationAction, mailallowmemberlistAction, memberprofiledetailsAction, pushnotificationallowmemberlistAction, updatememberprofileAction, uploadDocumentAction, uploadDocumentclubAction, eventlistDataAction, linklistdataAction, getfaqlistAction, getjoblistAction, getemployeelistAction, donationlistAction, volunteerlistAction, RegInterestListAction } from "../Action/AdminstratorAction";
// Define an initial state
const initialState = {
  Adminlist: null,
  loading: 'idle',
  error: null,
  admindetails: "",
  memberdetails: "",
  clubdetails: "",
  memberlists: [],
  regIntList: [],
  eventlist: [],
  getclublists: [],
  getbloglists: [],
  dashboard: {},
  blogdetails: "",
  categorysub: [],
  notificationlist: [],
  notificationallowuserlist: [],
  mailinglist: [],
  linklist: [],
  faqlist: [],
  joblist: [],
  employeelist: [],
  donationlist: [],
  volunteerlistlogs: []
};



const AdministratorSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // You can include synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(AdminlistAction.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(AdminlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.Adminlist = action.payload;
      }).
      addCase(ProfiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.admindetails = action.payload;
      }).
      addCase(volunteerlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.volunteerlistlogs = action.payload;
      })




      .addCase(UserlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberlists = action.payload;
      })
      .addCase(RegInterestListAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.regIntList = action.payload;
      })
      .addCase(memberprofiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })

      .addCase(eventlistDataAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.eventlist = action.payload;
      })

      .addCase(donationlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.donationlist = action.payload;
      })



      .addCase(linklistdataAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.linklist = action.payload;
      })

      .addCase(getfaqlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.faqlist = action.payload;
      })

      .addCase(getjoblistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.joblist = action.payload;
      })

      .addCase(getemployeelistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.employeelist = action.payload;
      })





      .
      addCase(accountsuspendedAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })

      .addCase(clubdetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      .addCase(usersuspendedAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(updatememberprofileAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(uploadDocumentAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })

      .addCase(uploadDocumentclubAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })

      .addCase(AdminlistAction.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(categoryandsubcategorylistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';

        state.categorysub = action.payload;
      })

      .addCase(mailallowmemberlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';

        state.mailinglist = action.payload;
      })





      .addCase(dashBoardAdminAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.dashboard = action.payload;
      })



      .addCase(listnotificationAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.notificationlist = action.payload;
      })
      .addCase(pushnotificationallowmemberlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.notificationallowuserlist = action.payload;
      })



      //blogs section
      .addCase(getbloglistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.getbloglists = action.payload;
      })
      .addCase(blogdetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.blogdetails = action.payload;
      })

      .addCase(addandupdateblogAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.blogdetails = action.payload;
      })



    //blogs section
  },
});

export default AdministratorSlice.reducer;