import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createlinkAction } from "../Redux/Action/AdminstratorAction";

export default function CreateLink() {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const formik = useFormik({
    initialValues: {
      title: "",
      url: "",
     
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      url: Yup.string().required("Link is required"),
    
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {

      dispatch(createlinkAction(values)).then(function(){
        navigate('/all-links')
      })



    },
  });


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Link</h2>
          
          </div>
         
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/all-links">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
        <form onSubmit={formik.handleSubmit}>

       
          <Col lg={8}>
            <div className="customer-form-new mt-4 mb-4">
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" name="title" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      />
                      {formik.submitCount>0 && formik.errors.title && <div className="errors">{formik.errors.title}</div>}
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>URL</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" name="url" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.url}
                      />
                      {formik.submitCount>0 && formik.errors.url && <div className="errors">{formik.errors.url}</div>}
                    </Form.Group>
                  </Col>

                
                  <Col lg={12} className="d-flex justify-content-center">
                    <div className="cmn-btn">
                      <button type="submit">Create</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          </form>
        </Row>
      </Container>
    </Layout>
  );
}
