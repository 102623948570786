import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useState } from "react";
import { volunteerlistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import Pagination from "../Components/Pagination";

export default function VolunteerLogs() {
  const { id } = useParams();


  

  const dispatch = useDispatch();

  var idvalue = Decryptedid(atob(id));
  const [serachkeyval, setSerachkeyval] = useState("");
  const [pageno, setPageno] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  useEffect(() => {
    dispatch(
      volunteerlistAction({
        userId:idvalue,
        perpage: perpage,
        pageno: pageno,
        serachkeyval:serachkeyval
        
       
      })
    );
    
  }, [idvalue,pageno,perpage]);




 
 const volunteerlistlogs = useSelector((state) => state.Administrator.volunteerlistlogs);

 const paginationevent = (pag) => {
  setPageno(pag);
};

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Volunteer Log</h2>
        </div>
        <hr />

        <hr />
       
      </div>
      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn tab">
                <Link to={"/user-profile/"+id}>Overview</Link>
                <Link to={"/donations/"+id} className="ms-2 ">
                  Donations
                </Link>
                <Link to={"/volunteer-logs/"+id} className="ms-2 active">
                  Volunteer Log
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
            <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (volunteerlistlogs?.Totalcheckin > 10 ? rangeEnd : volunteerlistlogs?.Totalcheckin) +
                    " of " +
                    volunteerlistlogs?.Totalcheckin?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example"   onChange={(e) => {
                    setPerpage(e.target.value);
                  }}>
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Organization</th>
                <th>PhoneNumber</th>
                <th>Purpose of visit</th>
                <th>Date</th>
                <th>Login Time</th>
                <th>Logout Time</th>
              
              </tr>
            </thead>
            <tbody>
              {
                volunteerlistlogs?.checkinlist?.map(function(object,i){
                  return(
                    <tr onClick={()=>{
                      
                     navigate('/view-volunteer-log/'+object?._id)
                    }}>
                    <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                <td>{object?.organizationname}</td>
                <td>{object?.phonenumber}</td>
                <td>{object?.visit}</td>
                <td>{moment(object?.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{moment(object?.intime).format("HH:mm")}</td>

                    <td>{object?.outtime==undefined?"": moment(object?.outtime).format("HH:mm")}</td>
               
               
                
              </tr>
                  )
                })
              }
             
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={volunteerlistlogs?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
