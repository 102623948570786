import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addandupdateemployeeAction, employeedetailsAction, employeeimageAction } from "../Redux/Action/AdminstratorAction";
import { useFormik } from "formik";
import * as Yup from "yup";
export default function ViewEmployee() {


  const [file, setFile] = useState();
  const [fileshow, setFileShow] = useState("");


  const { id } = useParams();
  
  var idvalue = Decryptedid(atob(id));
  const dispatch=useDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    dispatch(employeedetailsAction({ id: idvalue })).then(function (data) {
      if (data.payload) {
        var Details = data.payload;

        for (const [key, value] of Object.entries(Details)) {
          if (key != "_id" && key != "profileImage" ) {
            formik.setFieldValue(key, value, { strict: false });
          }

          if ( key == "profileImage" ) {
            formik.setFieldValue(key, value, { strict: false });
            setFileShow(value)
          }


     

          
        }
      }
    });
  }, [idvalue]);

  const formik=useFormik({
    initialValues:{
      name:"",
      email:"",
      phoneNumber:"",
      designation:"",
      profileImage:"",
      extension:""
     
      
    },
    validationSchema:Yup.object().shape({
      name:Yup.string().required('Name is required'),
      email:Yup.string().email().required('Email is required'),
      phoneNumber:Yup.number().required('Phone number is required'),
      designation:Yup.string().required('Designation is required'),
      profileImage:Yup.string(),
      extension:Yup.string().required('Extension is required'),
     
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      values.image=fileshow
      values.id=idvalue
      dispatch(addandupdateemployeeAction(values)).then(function(){
        navigate('/manage-employees')
      })
     

    }
  })
  function handleChange(e) {
    
    setFile(URL.createObjectURL(e.target.files[0]));
    let formdata=new FormData();
    formdata.append('employeeimage',e.target.files[0])

    dispatch(employeeimageAction(formdata)).then(function(data){
    
      formik.setFieldValue('profileImage',process.env.REACT_APP_IMAGE_URL+data?.payload?.filename,{strict:false})
    

     
      setFileShow(process.env.REACT_APP_IMAGE_URL+data?.payload?.filename)
    })
  }


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>View Employee</h2>
            
          </div>
         
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/manage-employees">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
      <form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="customer-form-new mt-4 mb-4">
              <div className="customer-form-inner">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Type Here"   name="name" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      />
                      {formik.submitCount>0 && formik.errors.name && <div className="errors">{formik.errors.name}</div>}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Type Here"   name="email" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      />
                      {formik.submitCount>0 && formik.errors.email && <div className="errors">{formik.errors.email}</div>}
                    </Form.Group>
                  </Col>
                  <Row>
                  <Col lg={6}>
                  <Row>
                  <Col lg={6} className="pe-0">
                    <Form.Group className="mb-3">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" 
                       name="phoneNumber" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onInput={(e) => {
                          const input = e.target;
                          const value = input.value.replace(/\D/g, "");
                          const formattedValue =
                            value.slice(0, 3) +
                            (value.length > 3 ? " " : "") +
                            value.slice(3, 6) +
                            (value.length > 6 ? " " : "") +
                            value.slice(6, 10);
                          input.value = formattedValue;
                        }}
                      value={formik.values.phoneNumber}
                      />
                      {formik.submitCount>0 && formik.errors.phoneNumber && <div className="errors">{formik.errors.phoneNumber}</div>}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="ps-0">
                    <Form.Group className="mb-3">
                      <Form.Label>Extension</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" 
                       name="extension" 
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                
                      value={formik.values.extension}
                      />
                      {formik.submitCount>0 && formik.errors.extension && <div className="errors">{formik.errors.extension}</div>}
                    </Form.Group>
                  </Col>
                  </Row>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control type="text"   placeholder="Ext"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.designation}
                      />
                      {formik.submitCount>0 && formik.errors.designation && <div className="errors">{formik.errors.designation}</div>}
                    </Form.Group>
                  </Col>
                  </Row>
                  
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Image</Form.Label>
                      <div className="image-upload">
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleChange}
                        />
                        <p>Upload Image</p>
                        <img className="preview-img" src={fileshow==""?file:fileshow} />
                      </div>
                    </Form.Group>
                  </Col>
                 
                  <Col lg={12} className="d-flex justify-content-center">
                    <div className="cmn-btn">
                      <button type="submit">Save</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        </form>
      </Container>
    </Layout>
  );
}
